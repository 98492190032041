import { Form } from "antd";
import GlobalContext from "./GlobalContext";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

const AppContext = (props) => {
  const [globalData, setGlobalData] = useState(
    sessionStorage.getItem("globalData")
      ? JSON.parse(sessionStorage.getItem("globalData"))
      : {}
  );
  const [isRtl, setIsRtl] = useState(localStorage.getItem("language") === "ar");

  const [form] = Form.useForm();

  const listNationlité = [
    {
      label: "Tunisienne",
      value: "Tunisienne",
    },
    {
      label: "Française",
      value: "Française",
    },
  ];

  const gouvernorats = {
    tunis: {
      ar: "تونس",
      fr: "Tunis",
      delegations: {
        ar: [
          "قرطاج",
          "المدينة",
          "باب البحر",
          "باب سويقة",
          "العمران",
          "العمران الأعلى",
          "التحرير",
          "المنزه",
          "حي الخضراء",
          "باردو",
          "السيجومي",
          "الزهور",
          "الحرائرية",
          "سيدي حسين",
          "الوردية",
          "الكبارية",
          "سيدي البشير",
          "جبل الجلود",
          "حلق الوادي",
          "الكرم",
          "المرسى",
        ],
        fr: [
          "Carthage",
          "La Medina",
          "Bab El Bhar",
          "Bab Souika",
          "El Omrane",
          "El Omrane Supérieur",
          "Ettahrir",
          "El Menzah",
          "Cité El Khadhra",
          "Le Bardo",
          "Sijoumi",
          "Ezzouhour",
          "El Hrairia",
          "Sidi Hassine",
          "El Ouardia",
          "El Kabaria",
          "Sidi El Béchir",
          "Djebel Djelloud",
          "La Goulette",
          "Le Kram",
          "La Marsa",
        ],
      },
    },
    ariana: {
      ar: "أريانة",
      fr: "Ariana",
      delegations: {
        ar: [
          "أريانة المدينة",
          "سكرة",
          "رواد",
          "قلعة الأندلس",
          "سيدي ثابت",
          "حي التضامن",
          "المنيهلة",
        ],
        fr: [
          "L'Ariana Ville",
          "Soukra",
          "Raoued",
          "Kalaât El Andalous",
          "Sidi Thabet",
          "Cité Ettadhamen",
          "El Mnihla",
        ],
      },
    },
    Bèja: {
      ar: "باجة",
      fr: "Bèja",
      delegations: {
        ar: [
          "باجة الشمالية",
          "باجة الجنوبية",
          "عمدون",
          "نفزة",
          "تبرسق",
          "تيبار",
          "تستور",
          "قبلاط",
          "مجاز الباب",
        ],
        fr: [
          "Béja Nord",
          "Béja Sud",
          "Amdoun",
          "Nefza",
          "Teboursouk",
          "Tibar",
          "Testour",
          "Goubellat",
          "Medjez El Bab",
        ],
      },
    },

    ben_arous: {
      ar: "بن عروس",
      fr: "Ben Arous",
      delegations: {
        ar: [
          "بن عروس",
          "المدينة الجديدة",
          "المروج",
          "حمام الأنف",
          "حمام الشط",
          "بومهل البساتين",
          "الزهراء",
          "رادس",
          "مقرين",
          "المحمدية",
          "فوشانة",
          "مرناق",
        ],
        fr: [
          "Ben Arous",
          "La Nouvelle Medina",
          "El Mourouj",
          "Hammam Lif",
          "Hammam Chôtt",
          "Bou Mhel El Bassatine",
          "Ezzahra",
          "Radès",
          "Megrine",
          "Mohamedia",
          "Fouchana",
          "Mornag",
        ],
      },
    },
    bizerte: {
      ar: "بنزرت",
      fr: "Bizerte",
      delegations: {
        ar: [
          "بنزرت الشمالية",
          "جرزونة",
          "بنزرت الجنوبية",
          "سجنان",
          "جومين",
          "ماطر",
          "غزالة",
          "منزل بورقيبة",
          "تينجة",
          "أوتيك",
          "غار الملح",
          "منزل جميل",
          "العالية",
          "رأس الجبل",
        ],
        fr: [
          "Bizerte Nord",
          "Zarzouna",
          "Bizerte Sud",
          "Sedjnane",
          "Djoumine",
          "Mateur",
          "Ghezala",
          "Menzel Bourguiba",
          "Tinja",
          "Utique",
          "Ghar El Meleh",
          "Menzel Djemil",
          "El Alia",
          "Ras Djebel",
        ],
      },
    },

    Gabès: {
      ar: "قابس",
      fr: "Gabès",
      delegations: {
        ar: [
          "قابس المدينة",
          "قابس الغربية",
          "قابس الجنوبية",
          "غنوش",
          "المطوية",
          "منزل الحبيب",
          "الحامة",
          "مطماطة",
          "مطماطة الجديدة",
          "مارث",
        ],
        fr: [
          "Gabes Medina",
          "Gabes Ouest",
          "Gabes Sud",
          "Ghanouch",
          "El Metouia",
          "Menzel El Habib",
          "El Hamma",
          "Matmata",
          "Nouvelle Matmata",
          "Mareth",
        ],
      },
    },
    gafsa: {
      ar: "قفصة",
      fr: "Gafsa",
      delegations: {
        ar: [
          "قفصة الشمالية",
          "سيدي عيش",
          "القصر",
          "قفصة الجنوبية",
          "ام العرائس",
          "الرديف",
          "المتلوي",
          "المضيلة",
          "القطار",
          "بلخير",
          "السند",
        ],
        fr: [
          "Gafsa Nord",
          "Sidi Aïch",
          "El Ksar",
          "Gafsa Sud",
          "Oum El Araies",
          "Redeyef",
          "Metlaoui",
          "Mdhila",
          "EL Guetar",
          "Belkhir",
          "Sned",
        ],
      },
    },
    jendouba: {
      ar: "جندوبة",
      fr: "Jendouba",
      delegations: {
        ar: [
          "جندوبة",
          "جندوبة الشمالية",
          "بوسالم",
          "طبرقة",
          "عين دراهم",
          "فرنانة",
          "غارالدماء",
          "وادي مليز",
          "بلطة بوعوان",
        ],
        fr: [
          "Jendouba",
          "Jendouba Nord",
          "Bou Salem",
          "Tabarka",
          "Ain Draham",
          "Fernana",
          "Ghardimaou",
          "Oued Meliz",
          "Balta - Bou Aouane",
        ],
      },
    },
    kairouan: {
      ar: "القيروان",
      fr: "Kairouan",
      delegations: {
        ar: [
          "القيروان الشمالية",
          "القيروان الجنوبية",
          "نصر الله",
          "الشراردة",
          "بوحجلة",
          "حفوز",
          "عين جلولة",
          "حاجب العيون",
        ],
        fr: [
          "Kairouan Nord",
          "Kairouan Sud",
          "Nasrallah",
          "Cherarda",
          "Bou Hajla",
          "Haffouz",
          "Ain Jlil",
          "Hajeb El Ayoun",
        ],
      },
    },
    kasserine: {
      ar: "القصرين",
      fr: "Kasserine",
      delegations: {
        ar: [
          "القصرين الشمالية",
          "القصرين الجنوبية",
          "الزهور",
          "حاسي الفريد",
          "سبيطلة",
          "سبيبة",
          "جدليان",
          "العيون",
          "تالة",
          "حيدرة",
          "فوسانة",
          "فريانة",
          "ماجل بلعباس",
        ],
        fr: [
          "Kasserine Nord",
          "Kasserine Sud",
          "Ezzouhour",
          "Hassi Ferid",
          "Sbeitla",
          "Sbiba",
          "Djedeliane",
          "El Ayoun",
          "Thala",
          "Hidra",
          "Foussana",
          "Feriana",
          "Majel Bel Abbès",
        ],
      },
    },
    kebili: {
      ar: "قبلي",
      fr: "Kebili",
      delegations: {
        ar: [
          "دوز الجنوبية",
          "دوز الشمالية",
          "فوار",
          "دوز الشمالية",
          "دوز الجنوبية",
          "سوق الاحد",
        ],
        fr: [
          "Kebili Sud",
          "Kebili Nord",
          "Souk El Ahed",
          "Douz Nord",
          "Douz Sud",
          "Faouar",
        ],
      },
    },
    kairouan: {
      ar: "القيروان",
      fr: "Kairouan",
      delegations: {
        ar: [
          "القيروان الشمالية",
          "القيروان الجنوبية",
          "الشبيكة",
          "السبيخة",
          "الوسلاتية",
          "حفوز",
          "العلاء",
          "حاجب العيون",
          "نصر الله",
          "الشراردة",
          "بوحجلة",
        ],
        fr: [
          "Kairouan Nord",
          "Kairouan Sud",
          "Echebika",
          "Sbikha",
          "EL Ouslatia",
          "Haffouz",
          "El Alâa",
          "Hajeb el Ayoun",
          "Nasrallah",
          "Echrarda",
          "Bouhajla",
        ],
      },
    },
    Mahdia: {
      ar: "المهدية",
      fr: "Mahdia",
      delegations: {
        ar: [
          "المهدية",
          "بومرداس",
          "أولاد شامخ",
          "شربان",
          "هبيرة",
          "السواسي",
          "الجم",
          "الشابة",
          "ملولش",
          "سيدي علوان",
          "قصورالساف",
        ],
        fr: [
          "Mahdia",
          "Bou Merdès",
          "Ouled Chamekh",
          "Chorbane",
          "Hebira",
          "Essouassi",
          "El Djem",
          "Chebba",
          "Melloulech",
          "Sidi Alouane",
          "Ksour Essef",
        ],
      },
    },

    Mannouba: {
      ar: "منوبة",
      fr: "Mannouba",
      delegations: {
        ar: [
          "منوبة",
          "دوار هيشر",
          "وادي الليل",
          "المرناقية",
          "برج العامري",
          "الجديدة",
          "طبربة",
          "البطان",
        ],
        fr: [
          "Mannouba",
          "Douar Hicher",
          "Oued Ellil",
          "Mornaguia",
          "Borj Amri",
          "Djedeida",
          "Tebourba",
          "El Battane",
        ],
      },
    },
    nabeul: {
      ar: "نابل",
      fr: "Nabeul",
      delegations: {
        ar: [
          "نابل",
          "دارشعبان الفهري",
          "بني خيار",
          "قربة",
          "منزل تميم",
          "الميدة",
          "قليبية",
          "حمام الأغزاز",
          "الهوارية",
          "تاكلسة",
          "سليمان",
          "منزل بوزلفة",
          "بني خلاد",
          "قرمبالية",
          "بوعرقوب",
          "الحمامات",
        ],
        fr: [
          "Nabeul",
          "Dar Châabane El Fehri",
          "Beni Khiar",
          "Korba",
          "Menzel Temime",
          "El Mida",
          "Kelibia",
          "Hammam El Guezaz",
          "El Haouaria",
          "Takelsa",
          "Soliman",
          "Menzel Bouzelfa",
          "Beni Khalled",
          "Grombalia",
          "Bou Argoub",
          "Hammamet",
        ],
      },
    },
    zaghouan: {
      ar: "زغوان",
      fr: "Zaghouan",
      delegations: {
        ar: ["زغوان", "الزريبة", "بئر مشارقة", "الفحص", "الناظور", "صواف"],
        fr: [
          "Zaghouan",
          "Ez-Zeriba",
          "Bir Mchergua",
          "El Fahs",
          "En-Nadhour",
          "Saouaf",
        ],
      },
    },
    "El Kef": {
      ar: "الكاف",
      fr: "El Kef",
      delegations: {
        ar: [
          "الكاف الغربية",
          "الكاف الشرقية",
          "نبر",
          "ساقية سيدي يوسف",
          "تاجروين",
          "قلعة سنان",
          "القلعة الخصباء",
          "الجريصة",
          "القصور",
          "الدهماني",
          "السرس",
        ],
        fr: [
          "Kef Ouest",
          "Kef Est",
          "Nebeur",
          "Sakiet Sidi Youssef",
          "Tajerouine",
          "Kalâat Snan",
          "Kalâat Khasbah",
          "Djerissa",
          "El Ksour",
          "Dahmani",
          "Es-Sers",
        ],
      },
    },
    siliana: {
      ar: "سليانة",
      fr: "Siliana",
      delegations: {
        ar: [
          "سليانة الشمالية",
          "سليانة الجنوبية",
          "بوعرادة",
          "ڨعفور",
          "الكريب",
          "بورويس",
          "مكثر",
          "الروحية",
          "كسرى",
          "برقو",
          "العروسة",
        ],
        fr: [
          "Siliana Nord",
          "Siliana Sud",
          "Bou Arada",
          "Gaâfour",
          "El Krib",
          "Bourouis",
          "Makthar",
          "Er-Rouhia",
          "Kesra",
          "Bargou",
          "El Aroussa",
        ],
      },
    },
    sousse: {
      ar: "سوسة",
      fr: "Sousse",
      delegations: {
        ar: [
          "سوسة المدينة",
          "سوسة الرياض",
          "سوسة جوهرة",
          "سوسة سيدي عبد الحميد",
          "حمام سوسة",
          "أكودة",
          "القلعة الكبرى",
          "سيدي بوعلي",
          "هرقلة",
          "النفيضة",
          "بوفيشة",
          "كندار",
          "سيدي الهاني",
          "مساكن",
          "القلعة الصغرى",
          "الزاوية - القصيبة - الثريات",
        ],
        fr: [
          "Sousse Medina",
          "Sousse Riadh",
          "Sousse Jawhara",
          "Sousse Sidi Abdelhamid",
          "Hammam Sousse",
          "Akouda",
          "Kalaâ Kebira",
          "Sidi Bou Ali",
          "Hergla",
          "Enfidha",
          "Bouficha",
          "Kondar",
          "Sidi El Héni",
          "M’saken",
          "Kalaâ Seghira",
          "Zaouia - Ksiba - Thrayet",
        ],
      },
    },
    monastir: {
      ar: "المنستير",
      fr: "Monastir",
      delegations: {
        ar: [
          "المنستير",
          "الوردانين",
          "الساحلين",
          "زرمدين",
          "بني حسان",
          "جمال",
          "بنبلة",
          "المكنين",
          "البقالطة",
          "طبلبة",
          "قصر هلال",
          "قصيبة المديوني",
          "صيادة - لمطة - بوحجر",
        ],
        fr: [
          "Monastir",
          "Ouerdanine",
          "Sahline",
          "Zermadine",
          "Beni Hassen",
          "Jammel",
          "Bembla",
          "Moknine",
          "Bekalta",
          "Teboulba",
          "Ksar Helal",
          "Ksibet El Mediouni",
          "Sayada-Lamta Bou-Hjar",
        ],
      },
    },
    sfax: {
      ar: "صفاقس",
      fr: "Sfax",
      delegations: {
        ar: [
          "صفاقس المدينة",
          "صفاقس الغربية",
          "ساقية الزيت",
          "ساقية الدائر",
          "صفاقس الجنوبية",
          "طينة",
          "عقارب",
          "جبنيانة",
          "العامرة",
          "الحنشة",
          "منزل شاكر",
          "الغريبة",
          "بئر علي بن خليفة",
          "الصخيرة",
          "المحرس",
          "قرقنة",
        ],
        fr: [
          "Sfax Ville",
          "Sfax Ouest",
          "Sakiet Ezzit",
          "Sakiet Eddaïer",
          "Sfax Sud",
          "Tina",
          "Agareb",
          "Djebeniana",
          "El Amra",
          "El Hencha",
          "Menzel Chaker",
          "Ghraiba",
          "Bir ali Ben Kelifa",
          "Skhira",
          "Mahres",
          "Kerkenah",
        ],
      },
    },
    sidi_bouzid: {
      ar: "سيدي بوزيد",
      fr: "Sidi Bouzid",
      delegations: {
        ar: [
          "سيدي بوزيد الغربية",
          "سيدي بوزيد الشرقية",
          "جلمة",
          "سبالة أولاد عسكر",
          "بئر الحفي",
          "سيدي علي بن عون",
          "منزل بوزيان",
          "المكناسي",
          "سوق الجديد",
          "المزونة",
          "الرقاب",
          "أولاد حفوز",
        ],
        fr: [
          "Sidi Bouzid Ouest",
          "Sidi Bouzid Est",
          "Jilma",
          "Cebalet Ouled Asker",
          "Bir El Hafey",
          "Sidi Ali Ben Aoûn",
          "Menzel Bouzaïenne",
          "Meknassy",
          "Souk Jedid",
          "Mezzouna",
          "Regueb",
          "Ouled Haffouz",
        ],
      },
    },
    Medenine: {
      ar: "مدنين",
      fr: "Medenine",
      delegations: {
        ar: [
          "مدنين الشمالية",
          "مدنين الجنوبية",
          "بني خداش",
          "بنقردان",
          "جرجيس",
          "جربة حومة السوق",
          "جربة ميدون",
          "جربة أجيم",
          "سيدي مخلوف",
        ],
        fr: [
          "Medenine Nord",
          "Medenine Sud",
          "Beni Khedech",
          "Ben Guerdane",
          "Zarzis",
          "Djerba Houmet Souk",
          "Djerba Midoun",
          "Djerba Ajim",
          "Sidi Makhloulf",
        ],
      },
    },
    tataouine: {
      ar: "تطاوين",
      fr: "Tataouine",
      delegations: {
        ar: [
          "تطاوين الشمالية",
          "تطاوين الجنوبية",
          "الصمار",
          "بئر الأحمر",
          "غمراسن",
          "ذهيبة",
          "رمادة",
        ],
        fr: [
          "Tataouine Nord",
          "Tataouine Sud",
          "Smâr",
          "Bir Lahmar",
          "Ghomrassen",
          "Dhehiba",
          "Remada",
        ],
      },
    },
    tozeur: {
      ar: "توزر",
      fr: "Tozeur",
      delegations: {
        ar: ["توزر", "دقاش", "تمغزة", "نفطة", "حزوة"],
        fr: ["Tozeur", "Degach", "Tameghza", "Nefta", "Hazoua"],
      },
    },
  };

  useEffect(() => {
    const language=localStorage.getItem("Language")? localStorage.getItem("Language") : 'fr'
    localStorage.setItem("language",language)
    const data =
      sessionStorage.getItem("globalData") &&
      JSON.parse(sessionStorage.getItem("globalData"));
    if (data) {
      const fieldsToSet = { ...data };
      Object.keys(data).forEach((key) => {
        if (key.startsWith("dateNaissancePersonnel_")) {
          fieldsToSet[key] = data[key] ? dayjs(data[key], "DD/MM/YYYY") : "";
        } else if (
          key === "dateExpiration" ||
          key === "dateNaissance" ||
          key === "dateNaissanceDirecteur" ||
          key === "dateNaissanceFormateur" ||
          key === "dateAutorisation"
        ) {
          fieldsToSet[key] = data[key] ? dayjs(data[key], "DD/MM/YYYY") : "";
        }
      });

      form.setFieldsValue(fieldsToSet);
    } else {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        naturesDeFormation: [
          {
            natureFormation: "",
            typeFormation: "",
            secteurFormation: "",
            sousSecteurFormation: "",
            specialiteFormation: "",
            niveauRequis: "",
            diplomeFormation: "",
            dureeMois: "",
            dureeHeures: "",
            domaineFormation: "",
            dureeMinimale: "",
            modeFormation: "",
          },
        ],
        espaces: [
          {
            typeEspace: "",
            libelleEspace: "",
            superficieEspace: "",
            capaciteEspace: "",
          },
        ],
        personnel: [
          {
            categoriePersonnel: "",
            emailPersonnel: "",
            situationPersonnel: "",
            nomPersonnel: "",
            prenomPersonnel: "",
            genrePersonnel: "",
            nationalitePersonnel: "",
            dateNaissancePersonnel_: null,
            adressePersonnel: "",
            gouvernoratPersonnel: "",
            delegationPersonnel: "",
            niveauScolaire: "",
            anneesExperience: "",
            cinPersonnel: "",
            passportPersonnel: "",
            dossierPersonnel: "",
            besoinSpecifique: false,
          },
        ],
      });
    }

    setGlobalData(data);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        globalData,
        setGlobalData,
        listNationlité,
        form,
        isRtl,
        setIsRtl,
        gouvernorats,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
