import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Table,
  Tooltip,
  Tag,
  Button,
  Space,
  notification,
} from "antd";
import {
  EyeOutlined,
  EditOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import classes from "../Etablissements/Etablissements.module.css";
import { useTranslation } from "react-i18next";
import { getAffaires, updateActionAffaire } from "../../api/geoprod";

const Brouillons = () => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: t("Dénomination"),
      key: "dénomination",
      render: (_, record) =>
        record.data_form?.legal_entity_official_name ||
        record.data_form?.establishment_data_trade_name,
    },
    {
      title: t("Directeur"),
      dataIndex: "directeur",
      key: "directeur",
      render: (_, record) =>
        record.data_form.establishment_director_data_last_name +
        " " +
        record.data_form.establishment_director_data_first_name,
    },
    {
      title: t("Gouvernorat"),
      key: "gouvernorat",
      render: (_, record) => record.data_form.investor_residence_governorate,
    },
    {
      title: t("Délégation"),
      dataIndex: "délégation",
      key: "délégation",
      render: (_, record) => record.data_form.investor_residence_delegation,
    },
    {
      title: t("Matricule fiscale"),
      key: "matricule",
      render: (_, record) =>
        record.data_form.establishment_data_tax_number || "non renseigné",
    },
    {
      title: t("Numéro de téléphone"),
      key: "phoneNum",
      render: (_, record) => record.data_form.investor_phone_number,
    },
    {
      title: t("Nature de formation"),
      key: "natureForm",
      render: (_, record) => record.data_form.establishment_data_training_type,
    },
    {
      title: t("Statut"),
      key: "statut",
      render: (_, record) => {
        return (
          <Tag style={{ cursor: "pointer" }} color={record?.etat_background}>
            {record?.etat}
          </Tag>
        );
      },
    },
    {
      title: t("Actions"),
      key: "Actions",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("Voir")}>
            <EyeOutlined
              onClick={() => {
                // navigate(`/demandes/${record.affaire_id}`);
              }}
            />
          </Tooltip>
          {record.etat === "Brouillon" && (
            <Tooltip title={t("Modifier")}>
              <EditOutlined
                onClick={() => {
                  // navigate(`/demandes/save/${record.affaire_id}`);
                }}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAffaires({
          id_user: JSON.parse(sessionStorage.getItem("-x-token-user")).id_user,
          id_entreprise: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .user.entreprise_id,
        });
        const temp = data?.aaData?.map((item, index) => {
          return {
            ...item,
            code: "EPF" + String(index + 1).padStart(3, "0"),
            data_form: JSON.parse(item.data_form),
          };
        });

        setDataSource(temp.filter((elem) => elem.etat === "Brouillon"));
        setLoading(false);
      } catch (error) {
        notification.error({
          message: t("Error !"),
          description:
            error?.response?.data?.message ||
            "Une erreur s'est produite lors de la récupération des données",
        });
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      <Row className={classes.listeRow}>
        <Card
          className={classes.listeCard}
          bordered={false}
          title={t("Liste de mes brouillons")}
        >
          <Table
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            className="ant-border-space"
            style={{ overflowX: "auto" }}
            pagination={false}
          />
        </Card>
      </Row>
    </div>
  );
};

export default Brouillons;
