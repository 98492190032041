import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Select,
  Card,
  notification,
  Spin,
} from "antd";

import { useNavigate } from "react-router-dom";
import classes from "./SignUp.module.css";
import PhoneInput from "antd-phone-input";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../context/GlobalContext";
import logo from "../../assets/images/sigaf.svg";
import republique from "../../assets/images/tunisia.png";
import dayjs from "dayjs";
import { checkCitizenStatusByCinDn } from "../../api/extern";
import { signUp } from "../../api/geoprod";
import { generatePassword } from "../../utils";

const { Option } = Select;

const SignUp = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(false);
  const {
    listNationlité,
    form,
    globalData,
    setGlobalData,
    setIsRtl,
    gouvernorats,
  } = useContext(GlobalContext);

  const handleGouvernoratChange = (value) => {
    setDelegations(gouvernorats[value] || []);
  };
  const role = new URLSearchParams(window.location.search).get("role");

  const handleLanguageChange = (value) => {
    localStorage.setItem("language", value);
    setGlobalData({
      ...globalData,
      language: value,
    });
    i18n.changeLanguage(value);

    if (value === "ar") {
      setIsRtl(true);
    } else {
      setIsRtl(false);
    }
  };

  const validator = (_, { valid }) => {
    // if (valid(true)) return Promise.resolve(); // strict validation
    if (valid()) return Promise.resolve(); // non-strict validation
    return Promise.reject(t("Veuillez valider le numéro de téléphone."));
  };

  sessionStorage.setItem("role", role);

  const [nationalité, setNationalité] = useState("Tunisienne");
  const [delegations, setDelegations] = useState([]);

  const handleFinish = async (values) => {
    try {
      setLoader(true);

      let status = null;

      if (nationalité === "Tunisienne") {
        status = await checkCitizenStatusByCinDn(values.birthDate, values.cin);

        setLoader(false);
        if (!status) return 0;
      }

      let payload = {
        user: {
          id: "",
          civilite: "",
          telephone: "",
          tel_urgence: "",
          mobile: "",
          tel2: "",
          mail: "",
          nom: "",
          prenom: "",
          matricule_rh: "",
          identite_reelle: "",
          skype: "",
          password: "",
          old_password: "",
          pointage: false,
          super_admin: false,
          admin_restreint: false,
          api_key: "a645c516-5f0a-e9a-655-d7b49430b1d3",
          entreprise_id: 6875,
          date_naiss_user: "",
          role_auto: [],
          active: "",
          server_in_mail_data: "",
          server_out_mail_data: "",
          port_in_mail_data: "",
          port_out_mail_data: "",
          ssl_mail_data: false,
          num_interne: "",
          photo: null,
          admin_tpv: 2,
          execution_tpv: 2,
          validate_paiement: 2,
          date_integration: null,
          heure_fin: "",
          heure_debut: "",
          proprietaire: false,
          adresse_auto: "",
          samedi_off: false,
          comparateur: "",
          tarificateur: 2,
          signature: "",
          groupe_commission: "",
          act_grp_pubpostage: 2,
          act_grp_negociateur: 2,
          act_grp_communication: 2,
          acces_maileva: 2,
          acces_fusion: 2,
          nom_producteur: "",
          prenom_producteur: "",
          qualite_producteur: "",
          orias_producteur: "",
          location: "",
          acces_ett: false,
          role:
            role === "investisseur"
              ? "060ad92489947d410d897474079c1477"
              : "13fe9d84310e77f13a6d184dbf1232f3",
          zone: "",
          code: "",
          act_grp_tags: 2,
          act_grp_statut: 2,
          hist: 2,
          hist_comm: 2,
          acces_communiques: 2,
          ecriture_transaction_financiere_client: 2,
          lecture_transaction_financiere_client: 2,
          lecture_transaction_financiere_distribution: 2,
          ecriture_transaction_financiere_distribution: 2,
          transaction_financiere: 2,
          user_mail_data: "",
          user_pwd_data: "",
          acces_reclamation_ticket: 2,
          acc_opportunite: [
            {
              id: 1,
              libele: "Prospection",
              cat: 1,
              type: "2",
            },
            {
              id: 5,
              libele: "Commercial",
              cat: 5,
              type: "2",
            },
            {
              id: 6,
              libele: "Classification des diplômes",
              cat: 6,
              type: "2",
            },
          ],
          acc_affaire: [
            {
              icon: "fa-solid fa-archive",
              id: 2,
              libele: "Enregistrement",
              cat: 2,
              type: "2",
            },
            {
              icon: "fa-solid fa-archive",
              id: 3,
              libele: "Emission",
              cat: 3,
              type: "2",
            },
            {
              icon: "fa-solid fa-archive",
              id: 10,
              libele: "Gestion ",
              cat: 10,
              type: "2",
            },
            {
              icon: "fa-solid fa-check",
              id: 12,
              libele: "Qualité et Validation",
              cat: 12,
              type: "2",
            },
            {
              icon: "fa-solid fa-graduation-cap",
              id: 13,
              libele: "Expertise & pré-enregistrement",
              cat: 13,
              type: "2",
            },
            {
              icon: "fa-solid fa-ad",
              id: 14,
              libele: "Pré-Enregistrement ATRIAS",
              cat: 14,
              type: "2",
            },
            {
              icon: "fa-solid fa-envelope-square",
              id: 15,
              libele: "Enregistrement ATRIAS",
              cat: 15,
              type: "2",
            },
            {
              icon: "fa-solid fa-birthday-cake",
              id: 16,
              libele: "Gestion ATRIAS",
              cat: 16,
              type: "2",
            },
            {
              icon: "fa-solid fa-allergies",
              id: 822,
              libele: "Conformité et Qualité",
              cat: 822,
              type: "2",
            },
            {
              icon: "",
              id: 825,
              libele: "Commercial",
              cat: 825,
              type: "2",
            },
            {
              icon: "fa-solid fa-ad",
              id: 826,
              libele: "Expertise",
              cat: 826,
              type: "2",
            },
            {
              icon: "fa-solid fa-graduation-cap",
              id: 827,
              libele: "Direction Regionale",
              cat: 827,
              type: "2",
            },
            {
              icon: "fa-solid fa-address-book",
              id: 828,
              libele: "Sous Direction Regionale",
              cat: 828,
              type: "2",
            },
          ],
          manager_list: [],
          manager_de: [],
          groupe_user: [],
          tab_code: [],
          groupe_user_alert: [],
          nom_organisme: "",
          type_user: "c81e728d9d4c2f636f067f89cc14862c",
          information_bancaire: {
            id: "",
            titulaire_compte: "",
            iban: "",
            bic_swift: "",
            type_iban: "prestation",
            nom_banque: "",
          },
          espace_distrib_access: {
            changement_statut: 2,
            acces_info_spec: 2,
          },
          acces_qualite: 2,
          nom_role: "",
        },
        adresse: {
          CP: "",
          streetNumber: "",
          streetName: "",
          longitude: 0,
          latitude: 0,
          geom: null,
        },
        ville: {
          id_pays: "",
          nom_comm: "",
        },
      };

      payload.user.nationality = values?.nationality;
      payload.user.cin = values?.cin;
      payload.user.passport = values?.passportNumber;
      payload.user.nom = values?.user?.nom || status?.nomFr;
      payload.user.prenom = values?.user?.prenom || status?.prenomFr;
      payload.user.civilite = values?.user?.civilite;
      payload.user.mail = values?.user?.mail;
      payload.user.password = values?.user?.password || generatePassword();
      payload.user.mobile = `+${values.phone.countryCode}${values.phone.areaCode}${values.phone.phoneNumber}`;
      payload.user.telephone = `+${values.phone.countryCode}${values.phone.areaCode}${values.phone.phoneNumber}`;
      payload.user.date_naiss_user = dayjs(values.birthDate).format(
        "YYYY-MM-DD"
      );
      payload.ville.nom_comm = values?.ville?.nom_comm;
      payload.user.adresse = values?.ville?.nom_comm;
      payload.user.birthplace = values?.birthPlace || "";
      payload.user.delegation = values?.residenceDelegation || "";
      payload.user.goverment = values?.residenceGovernorate || "";
      payload.user.first_login = true;
      payload.adresse.CP = values?.adresse?.CP;

      await signUp(payload);

      notification.success({
        message: t("Votre inscription est enregistrée"),
        description: t(
          "Merci de confirmer votre compte pour finaliser la création de votre compte."
        ),
      });
      setLoader(false);
      navigate("/signin");
    } catch (error) {
      setLoader(false);
      notification.error({
        message: "Erreur !",
        description:
          error?.response?.data?.message ||
          "Erreur lors de la création de votre compte.",
      });
    }
  };

  return (
    <Row gutter={24} style={{ display: "flex", justifyContent: "center" }}>
      <Col
        lg={24}
        style={{
          marginBottom: "4rem",
          display: "flex",
          justifyContent: "space-around",
          backgroundColor: "white",
          width: "100%",
          padding: "1rem",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            alignItems: "center",
          }}
        >
          <img src={republique} style={{ height: "86px" }} />

          <img src={logo} />
          <Select
            style={{
              width: "8rem",
              display: "flex",
              alignItems: "center",
            }}
            onChange={handleLanguageChange}
            value={localStorage.getItem("language") || "fr"}
          >
            <Option value="fr">Français</Option>
            <Option value="ar">العربية</Option>
          </Select>
        </Row>
      </Col>
      <Col lg={14}>
        <Card
          style={{
            direction:
              localStorage.getItem("language") === "ar" ? "rtl" : "ltr",
          }}
        >
          <div className="p-4 p-sm-6">
            <h3 style={{ marginBottom: "2rem" }}>
              {t("Créer un nouveau compte")}
            </h3>

            <Form
              disabled={loader}
              form={form}
              layout="vertical"
              initialValues={{ nationality: "Tunisienne" }}
              onFinish={handleFinish}
            >
              {role === "investisseur" ? (
                <Row gutter={16}>
                  <Col lg={12} md={24}>
                    <Form.Item
                      label={t("Adresse e-mail")}
                      name={["user", "mail"]}
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                        {
                          pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: t("Veuillez vérifier ce champ."),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={24}>
                    <Form.Item
                      label={t("Nationalité")}
                      name={"nationality"}
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <Select
                        onChange={(e) => {
                          setNationalité(e);
                        }}
                        options={listNationlité}
                      />
                    </Form.Item>
                  </Col>
                  {nationalité === "Tunisienne" && (
                    <Col lg={12} md={24}>
                      <Form.Item
                        label={t("Numéro CIN")}
                        name="cin"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                        ]}
                      >
                        <Input
                          maxLength={8}
                          onChange={(e) =>
                            form.setFieldValue(
                              "cin",
                              e.target.value.replace(/[^+0-9]/g, "")
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {nationalité !== "Tunisienne" && (
                    <Col lg={12} md={24}>
                      <Form.Item
                        label={t("Passeport")}
                        name="passportNumber"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            form.setFieldValue(
                              "passportNumber",
                              e.target.value.replace(/[^+0-9]/g, "")
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {nationalité !== "Tunisienne" && (
                    <Col lg={12} md={24}>
                      <Form.Item
                        label={t("Date d’expiration du passeport")}
                        name="passportExpiry"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} placeholder="" />
                      </Form.Item>
                    </Col>
                  )}
                  {nationalité !== "Tunisienne" && (
                    <Col lg={12} md={24}>
                      <Form.Item
                        label={t("Nom")}
                        name={["user", "nom"]}
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  )}
                  {nationalité !== "Tunisienne" && (
                    <Col lg={12} md={24}>
                      <Form.Item
                        label={t("Prénom")}
                        name={["user", "prenom"]}
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  )}
                  <Col lg={12} md={24}>
                    <Form.Item
                      label={t("Date de naissance")}
                      name="birthDate"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <DatePicker
                        format={"DD/MM/YYYY"}
                        style={{ width: "100%" }}
                        placeholder=""
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={24}>
                    <Form.Item
                      label={t("Numéro de téléphone")}
                      name="phone"
                      rules={[{ validator }]}
                    >
                      <PhoneInput
                        country="tn" // Default country code (example for Tunisia)
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Row gutter={16}>
                  <Col lg={5} md={24}>
                    <Form.Item
                      label={t("Nationalité")}
                      name="nationality"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez sélectionner une nationalité."),
                        },
                      ]}
                    >
                      <Select
                        onChange={(e) => setNationalité(e)}
                        options={listNationlité}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={4} md={24}>
                    <Form.Item
                      label={t("Genre")}
                      name={["user", "civilite"]}
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez sélectionner un genre."),
                        },
                      ]}
                    >
                      <Select>
                        <Option value="M.">{t("Homme")}</Option>
                        <Option value="Mme">{t("Femme")}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {nationalité === "Tunisienne" && (
                    <Col lg={15} md={24}>
                      <Form.Item
                        label={t("Numéro CIN")}
                        name="cin"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                        ]}
                      >
                        <Input
                          maxLength={8}
                          onChange={async (e) => {
                            form.setFieldValue(
                              "cin",
                              e.target.value.replace(/[^+0-9]/g, "")
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {nationalité !== "Tunisienne" && (
                    <Col lg={6} md={24}>
                      <Form.Item
                        label={t("N° passeport")}
                        name="passportNumber"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            form.setFieldValue(
                              "passportNumber",
                              e.target.value.replace(/[^+0-9]/g, "")
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {nationalité !== "Tunisienne" && (
                    <Col lg={9} md={24}>
                      <Form.Item
                        label={t("Date d’expiration du passeport")}
                        name="passportExpiry"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format="DD/MM/YYYY"
                          placeholder=""
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col lg={10} md={24}>
                    <Form.Item
                      label={t("Adresse e-mail")}
                      name={["user", "mail"]}
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                        {
                          type: "email",
                          message: t("Veuillez vérifier ce champ."),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={7} md={24}>
                    <Form.Item
                      label={t("Nom")}
                      name={["user", "nom"]}
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={7} md={24}>
                    <Form.Item
                      label={t("Prénom")}
                      name={["user", "prenom"]}
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col lg={5} md={24}>
                    <Form.Item
                      label={t("Date de naissance")}
                      name="birthDate"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <DatePicker
                        format="DD/MM/YYYY"
                        style={{ width: "100%" }}
                        placeholder=""
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={24}>
                    <Form.Item
                      label={t("Lieu de naissance")}
                      name="birthPlace"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col lg={11} md={24}>
                    <Form.Item
                      label={t("Adresse")}
                      name={["ville", "nom_comm"]}
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={24}>
                    <Form.Item
                      label={t("Gouvernorat de résidence")}
                      name="residenceGovernorate"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <Select onChange={handleGouvernoratChange}>
                        {Object.keys(gouvernorats).map((gouvernorat) => (
                          <Option key={gouvernorat} value={gouvernorat}>
                            {gouvernorat}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={24}>
                    <Form.Item
                      label={t("Délégation de résidence")}
                      name="residenceDelegation"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <Select>
                        {delegations.map((delegation) => (
                          <Option key={delegation} value={delegation}>
                            {delegation}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={24}>
                    <Form.Item
                      label={t("Code postal")}
                      name={["adresse", "CP"]}
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col lg={12} md={24}>
                    <Form.Item
                      label={t("Numéro de téléphone")}
                      name="phone"
                      rules={[{ validator }]}
                    >
                      <PhoneInput
                        country="tn" // Default country code
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col>
                  <Button
                    type="primary"
                    style={{
                      marginInline: "0.5rem",
                      width: "7rem",
                    }}
                    htmlType="submit"
                  >
                    {loader && <Spin />}
                    {t("Valider")}
                  </Button>
                </Col>
                <Col>
                  <Button
                    disabled={loader}
                    style={{
                      marginInline: "0.5rem",
                      width: "7rem",
                    }}
                    htmlType="button"
                    onClick={() => {
                      // handle cancel
                    }}
                  >
                    {t("Annuler")}
                  </Button>
                </Col>
              </Row>
            </Form>
            {/* Form END */}
          </div>
          <p>
            {t("Vous-avez déjà un compte ?")}
            <span
              className={classes.seConnecter}
              onClick={() => navigate("/signin")}
            >
              {" "}
              {t("Se connecter")}
            </span>
          </p>
        </Card>
      </Col>

      {/* </Col> */}
    </Row>
  );
};
export default SignUp;
