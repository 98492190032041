import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  ar: {
    translation: {
      "Error !": "خطأ !",
      "Votre inscription est enregistrée": "تم تسجيل تسجيلك",
      "Merci de confirmer votre compte pour finaliser la création de votre compte.":
        "يرجى تأكيد حسابك لإتمام إنشاء حسابك.",
      "Le Couple CIN/Date de naissance est erroné":
        "رقم التعريف الشخصي/تاريخ الميلاد غير صحيح",
      "Sign up": "اشتراك",
      "Adresse e-mail": "البريد الإلكتروني",
      Nationalité: "الجنسية",
      "Date de naissance": "تاريخ الولادة",
      "Numéro de téléphone": "رقم الهاتف",
      Passeport: "رقم جواز السفر",
      "Date d’expiration du passeport": "تاريخ انتهاء جواز السفر",
      "Liste de mes EPF": "هياكل التكوين الخاصة بي",
      Investisseur: "المستثمر",
      "Type de personne": "نوع الشركة",
      "Personne Physique": "شخص طبيعي",
      "Personne Morale": "شخص معنوي",
      "Nom de l'investisseur": "اسم المستثمر",
      "Prénom de l'investisseur": "لقب المستثمر",
      "Adresse de résidence": "عنوان الإقامة",
      "Niveau scolaire": "المستوى الدراسي",
      Nature: "الطبيعة",
      Type: "النوع",
      Homologuée: "معترف بها",
      "Non homologuée": "غير معترف بها",
      Formation: "التكوين",
      Secteur: "القطاع",
      "Sous-secteur": "القطاع الفرعي",
      Spécialité: "التخصص",
      Diplôme: "الشهادة",
      "Niveau requis": "المستوى المطلوب",
      "Durée en (H)": "المدة بالساعات",
      "Durée en (mois)": "المدة بالأشهر",
      "Durée minimale": "المدة الدنيا",
      "Mode de formation": "طريقة التكوين",
      "Ajouter une formation": "إضافة تكوين",
      "Création d'un nouvel EPF": "إنشاء هيكل تكوين خاص جديد",
      Pages: "الصفحات",
      "Personne à besoin spécifique": "احتياجات خاصة",
      Genre: "الجنس",
      "Lieu de naissance": "مكان الولادة",
      "Numéro de passeport": "رقم جواز السفر",
      "Gouvernorat de résidence": "ولاية الإقامة",
      "Délégation de résidence": "معتمدية الإقامة",
      "Code postal": "الترقيم البريدي",
      "Dénomination commerciale": "الإسم التجاري",
      "Numéro de réservation": "رقم الحجز",
      "Type de l'EPF": "نوع هيكل التكوين الخاص",
      Logo: "الشعار",
      "Numéro fiscal": "المعرف الجبائي",
      "Numéro CNSS": "رقم الصندوق الوطني للضمان الاجتماعي",
      "Numéro de téléphone 1": "رقم الهاتف 1",
      "Numéro de téléphone 2": "رقم الهاتف 2",
      "Numéro de fax": "رقم الفاكس",
      "Matricule CNSS": "رقم التسجيل في الصندوق الوطني للضمان الاجتماعي",
      "Nature de formation": "طبيعة التكوين",
      "Adresse de l’établissement": "عنوان الهيكل",
      Gouvernorat: "الولاية",
      Délégation: "المعتمدية",
      "Données de l’établissement de formation": "معطيات هيكل التكوين الخاص",
      "Données de Directeur de l’Établissement": "بيانات مديرالهيكل",
      Nom: "الاسم",
      Prénom: "اللقب",
      "Date d’autorisation de ministre": "تاريخ ترخيص الوزير",
      "Numéro CIN": "رقم بطاقة التعريف الوطنية",
      Adresse: "العنوان",
      "Dossier du directeur": "ملف المدير (السيرة الذاتية وغيرها)",
      "Nombre d’années d’expérience": "عدد سنوات الخبرة",
      "Données sur la nature de formation": "بيانات حول طبيعة التكوين",
      "Données des Formateurs": "بيانات المكونين",
      Dossier: "الملف",
      "Domaines de spécialité": "مجالات التخصص",
      Situation: "الوضعية",
      "Données des Espaces": "بيانات الفضاءات",
      Espace: "فضاء",
      Libellé: "الوصف",
      "Superficie en m²": "المساحة بالمتر المربع",
      "Capacité en personne": "طاقة الإستيعاب للأشخاص",
      "Données des Équipements": "بيانات المعدات",
      Nombre: "العدد",
      "Fiche technique": "البطاقة الفنية",
      "Données du personnel d'encadrement et de gestion":
        "بيانات أعوان الإحاطة والتأطير",
      Personnel: "العون",
      Catégorie: "الفئة",
      "Années d’expérience professionnelle": "سنوات الخبرة المهنية",
      "Dossier de personnel": "ملف الموظف",
      "J’ai lu et accepté les termes du Cahier des Charges (CDC) lié à la création d’un EPF.":
        "أصّرح بأّني اطّلعت ووافقت على كراس الشروط المتعلق بضبط قواعد إحداث وسير هياكل التكوين الخاصة",
      Présentielle: "حضورية",
      "À distance": "عن بعد",
      "En alternance": "بالتناوب",
      Initiale: "أولية",
      Continue: "مستمرة",
      "Initiale et continue": "أولية ومستمرة",
      Permanent: "قار",
      Vacataire: "متعاقد",
      Principal: "رئيسي",
      Annexe: "ملحق",
      Homme: "ذكر",
      Femme: "أنثى",
      "Créer un nouvel EPF": "إنشاء هيكل تكوين خاص جديد",
      "Sélectionnez la situation d'EPF": "إختر وضعية هيكل التكوين الخاص",
      "Nouvel EPF": "هيكل تكوين خاص جديد",
      "EPF existant": "هيكل تكوين خاص موجود",
      Valider: "تأكيد",
      Annuler: "إلغاء",
      Consulter: "الإطلاع",
      Modifier: "تعديل",
      Fermer: "إغلاق",
      Actions: "إجراءات",
      Statut: "الوضعية",
      Code: "الرمز",
      Soumettre: "تقديم",
      Confirmation: "تأكيد",
      "Veuillez valider le numéro de téléphone.": "يرجى تأكيد رقم الهاتف.",
      "Êtes-vous sûr(e) de vouloir soumettre ces informations ?":
        "هل أنت متأكد(ة) من رغبتك في تقديم هذه المعلومات ؟",
      "Si vous êtes certain(e) que toutes les informations sont correctes, cliquez sur 'Valider' pour continuer.":
        " إذا كنت متأكد(ة) أن جميع المعلومات صحيحة، انقر على 'تأكيد' للمتابعة.",
      "Soumettre la demande": "تقديم المطلب",
      "Ajouter un personnel": "إضافة عون",
      "Ajouter un espace": "إضافة فضاء",
      "Dénomination officielle": "التسمية الرسمية",
      "Date d’expiration de la réservation": "تاريخ انتهاء الحجز",
      "Forme Juridique": "الشكل القانوني",
      "Adresse du siège social": "عنوان المقر الرئيسي",
      "Secteur d’activité": "قطاع النشاط",
      "Numéro d’identifiant fiscal / RNE": "رقم المعرف الجبائي / RNE",
      "Numéro de matricule CNSS":
        "رقم الانخراط في الصندوق الوطني للضمان الاجتماعي",
      "Liste de mes EPFs": "هياكل التكوين الخاصة بي",
      "Liste de mes établissements": "قائمة هياكل التكوين الخاصة بي",
      Dénomination: "التسمية",
      Code: "الرمز",
      Directeur: "المدير",
      "Matricule fiscale": "المعرف الجبائي",
      "Mes demandes": "طلباتي",
      "Liste de mes demandes": "قائمة طلباتي",
      Demandes: "المطالب",
      Établissements: "المؤسسات",
      Formateurs: "المكونون",
      Identité: "الهوية",
      "Continue et Initiale": "مستمرة وأولية",
      "Décrivez si la personne a des besoins spécifiques ou non":
        "حدد إذا كانت هناك احتياجات خاصة للشخص أم لا",
      Oui: "نعم",
      Non: "لا",
      Encadrement: "التأطير",
      Gestion: "الإحاطة",
      Permanent: "قار",
      "Non permanent": "غير قار",
      "Espace investisseur": "فضاء المستثمر",
      Primaire: "ابتدائي",
      Secondaire: "ثانوي",
      Universitaire: "جامعي",
      "Télécharger le logo": "تحميل الشعار",
      "Cliquez ou glissez pour télécharger le CV et le dossier du directeur":
        "انقر أو اسحب لتحميل السيرة الذاتية وملف المدير",
      "Cliquez ou glissez pour télécharger le  dossier du formateur":
        "انقر أو اسحب لتحميل ملف المكون",
      "Reprendre l’activité": "استئناف النشاط",
      "Veuillez remplir tous les champs obligatoires.":
        "يرجى ملء جميع الخانات الإلزامية.",
      "Veuillez remplir ce champ.": ".يرجى ملء هذه الخانة",
      "Veuillez vérifier ce champ.": ".يرجى التحقق من هذه الخانة",
      Baccalauréat: "البكالوريا",
      Licence: "الإجازة",
      Master: "الماجستير",
      Doctorat: "الدكتوراه",
      "Votre demande a été ajouté avec succés": "تم إضافة مطلبك بنجاح",
      "Vous êtes sûr de vouloir soumettre cette demande ?":
        "هل أنت متأكد من رغبتك في تقديم هذا المطلب؟",
      "Numéro d'enregistrement": "رقم التسجيل",
      Domaine: "المجال",
      "Créer un nouveau compte": "إنشاء حساب",
      "Vous-avez déjà un compte ?": "هل لديك حساب ؟",
      "Se connecter": "تسجيل الدخول",
      "Mot de passe": "كلمة المرور",
      "Nouveau mot de passe": "كلمة المرور الجديدة",
      "Confirmer le mot de passe": "تأكيد كلمة المرور",
      "Le mot de passe doit contenir 6 caractères alphanumériques (A-Z, a-z, 0-9) et des caractères spéciaux (*/.@-)":
        " يجب أن تحتوي كلمة المرور على 6 أحرف أبجدية رقمية (A-Z، a-z، 0-9) وأحرف خاصة (*/.@-)",
      "Le mot de passe et la confirmation doivent être identiques.":
        "يجب أن تكون كلمة المرور والتأكيد متطابقين.",
      "Vous n'avez pas de compte ?": "ليس لديك حساب؟",
      "Créez-en un": " أنشئ حسابًا",
      "Liste des formateurs": "قائمة المكونين",
      formateurs: "المكونون",
      Profile: "الملف الشخصي",
      "Espace formateur": "فضاء المكون",
      profile: "ملفي الشخصي",
      "Se déconnecter": "سجيل الخروج ",
      "Dénomination EPF": "التسمية",
      "Modifier Epf": " تعديل الهيكل",
      Etablissement: "الهيكل",
      "J'ai pris connaissance et accepté les termes du CDC relatifs à la création d'un EPF.":"لقد قرأت ووافقت على شروط كراس الشروط المتعلقة بإنشاء هيكل تكوين خاص",
      "Je confirme":"تأكيد",
      "Brouillon":"مسودة",
      "Merci de cocher la case pour attester que vous avez pris connaissance des termes du CDC et que vous les acceptez concernant la création d'un EPF.":"يرجى وضع علامة في المربع لتأكيد أنك قرأت ووافقت على شروط كراس الشروط المتعلقة بإنشاء هيكل تكوين خاص"
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng:
    localStorage.getItem("language") ||
    navigator.language ||
    navigator.userLanguage,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
