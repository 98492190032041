import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Form, Input, Card, Select, notification } from "antd";

import classes from "./Signin.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import signin from "../../assets/images/imagee.svg";
import GlobalContext from "../../context/GlobalContext";
import logo from "../../assets/images/sigaf.svg";
import republique from "../../assets/images/tunisia.png";
import { useTranslation } from "react-i18next";
import { login } from "../../api/geoprod";
const SignIn = (props) => {
  const navigate = useNavigate();
  const { form, globalData, setGlobalData, setIsRtl } =
    useContext(GlobalContext);
  const { setToken, setRole } = props;
  const { Option } = Select;

  const { t, i18n } = useTranslation();
  const handleLanguageChange = (value) => {
    localStorage.setItem("language", value);
    setGlobalData({
      ...globalData,
      language: value,
    });
    i18n.changeLanguage(value);

    if (value === "ar") {
      setIsRtl(true);
    } else {
      setIsRtl(false);
    }
  };

  const handleFinish = async (values) => {
    try {
      const data = await login(values.email, values.password);
      setToken(data.Acces_Token);
      setRole(
        data.id_role === "13fe9d84310e77f13a6d184dbf1232f3"
          ? "formateur"
          : "investisseur"
      );
      sessionStorage.setItem(
        "role",
        data.id_role === "13fe9d84310e77f13a6d184dbf1232f3"
          ? "formateur"
          : "investisseur"
      );
      sessionStorage.setItem("-x-token-user", JSON.stringify(data));
      navigate(data.id_role === "13fe9d84310e77f13a6d184dbf1232f3" ? "/profile" : "/demandes");
    } catch (error) {
      notification.error({
        message: t("Error !"),
        description: error?.response?.data?.message || t("Une erreur s'est produite lors de la connexion"),
      })
    }
  };
  return (
    <Row gutter={24} style={{ display: "flex", justifyContent: "center" }}>
      <Col
        lg={24}
        style={{
          marginBottom: "4rem",
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "4rem",
          backgroundColor: "white",
          width: "100%",
          padding: "1rem",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            alignItems: "center",
          }}
        >
          <img src={republique} style={{ height: "86px" }} />

          <img src={logo} />
          <Select
            style={{
              width: "8rem",
              display: "flex",
              alignItems: "center",
            }}
            onChange={handleLanguageChange}
            value={localStorage.getItem("language") || "fr"}
          >
            <Option value="fr">Français</Option>
            <Option value="ar">العربية</Option>
          </Select>
        </Row>
      </Col>

      {/* <Col lg={8} style={{ display: "flex", alignItems: "center" }}>
        <div className="p-3 p-lg-5">
          <img src={signin} alt="signin" />
        </div>
        <div />
      </Col> */}
      <Col lg={9}>
        <Card
          className="p-4 p-sm-7"
          style={{
            direction:
              localStorage.getItem("language") === "ar" ? "rtl" : "ltr",
          }}
        >
          <h2 className="mb-2">{t("Se connecter")}</h2>

          <Form form={form} layout="vertical" onFinish={handleFinish}>
            <Form.Item
              label={t("Adresse e-mail")}
              name="email"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: t("Veuillez vérifier ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("Mot de passe")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="recaptcha"
              rules={[
                {
                  required: true,
                  message: t("Veuillez vérifier ce champ."),
                },
              ]}
            >
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                // onChange={onCaptchaChange}
              />
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Form.Item>
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "#21A8A8",
                    width: "7rem",
                    color: "white",
                  }}
                  htmlType="submit"
                >
                  {t("Se connecter")}
                </Button>
              </Form.Item>
              <p style={{ textAlign: "right" }}>
                {t("Vous n'avez pas de compte ?")}{" "}
                <span
                  className={classes.seConnecter}
                  onClick={() => navigate("/signup?role=investisseur")}
                >
                  {t("Créez-en un")}
                </span>
              </p>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default SignIn;
