import { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";
import { Model, FunctionFactory } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { Button, Modal, notification, Row, Space, Spin } from "antd";
import {
  ExclamationCircleOutlined,
  SaveOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import classes from "./CreateEpf.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// For auto tranlsating the error messages.
import "survey-core/survey.i18n";
import { checkCitizenStatusByCinDn } from "../../../api/extern";
import dayjs from "dayjs";
import axios from "axios";
import {
  getTrainerByNationalityAndIdentity,
  initAffaire,
  saveAffaire,
  updateAffaire,
} from "../../../api/geoprod";
import PdfViewer from "../../PdfViewer";
// import { Document, Page, pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const CreateEpf = () => {
  const { i18n } = useTranslation();
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("-x-token-user"))
  );
  const [defaultValues, setDefaultValues] = useState({});
  // const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { gouvernorats } = useContext(GlobalContext);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const gouvernoratList = {
    ar: [
      "تونس",
      "بن عروس",
      "أريانة",
      "منوبة",
      "جندوبة",
      "الكاف",
      "سليانة",
      "باجة",
      "نابل",
      "زغوان",
      "القيروان",
      "القصرين",
      "سيدي بوزيد",
      "قفصة",
      "توزر",
      "قبلي",
      "سوسة",
      "المهدية",
      "المهدية",
      "صفاقس",
      "قابس",
      "مدنين",
      "تطاوين",
    ],
    fr: [
      "Tunis",
      "Ben Arous",
      "Ariana",
      "Mannouba",
      "Jendouba",
      "El Kef",
      "Siliana",
      "Bizerte",
      "Bèja",
      "Nabeul",
      "Zaghouan",
      "Kairouan",
      "Kasserine",
      "Sidi Bouzid",
      "Gafsa",
      "Tozeur",
      "Kebili",
      "Sousse",
      "Mahdia",
      "Monastir",
      "Sfax",
      "Gabès",
      "Medenine",
      "Tataouine",
    ],
  };

  const surveyJson = {
    locale: i18n.language,
    logoPosition: "right",
    pages: [
      {
        name: "page1",
        title: {
          default: "Données de l'investisseur",
          ar: "بيانات المستثمر",
        },
        elements: [
          {
            type: "panel",
            name: "Investisseur",
            elements: [
              {
                type: "radiogroup",
                name: "inverstor_type",
                title: {
                  default: "Type de l'établissement",
                  ar: "الصبغة القانونية",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                renderAs: "div",
                choices: [
                  {
                    value: "Personne physique",
                    text: {
                      default: "Personne physique",
                      ar: "شخص طبيعي",
                    },
                  },
                  {
                    value: "Personne morale",
                    text: {
                      default: "Personne morale",
                      ar: "شخص معنوي",
                    },
                  },
                ],
                choicesOrder: "asc",
                colCount: 4,
              },
              {
                type: "text",
                name: "investor_last_name_ar",
                title: {
                  default: "Nom de l'investisseur (Arab)",
                  ar: "اسم المستثمر (عربي)",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "investor_first_name_ar",
                startWithNewLine: false,
                title: {
                  default: "Prénom de l'investisseur (Arab)",
                  ar: "لقب المستثمر (عربي)",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "investor_last_name_fr",
                title: {
                  default: "Nom de l'investisseur (Français)",
                  ar: "اسم المستثمر (بالفرنسية)",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "investor_first_name_fr",
                startWithNewLine: false,
                title: {
                  default: "Prénom de l'investisseur (Français)",
                  ar: "لقب المستثمر (بالفرنسية)",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "investor_residence_address",
                title: {
                  default: "Adresse de résidence",
                  ar: "عنوان الإقامة",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "dropdown",
                name: "investor_eduction_level",
                startWithNewLine: false,
                title: {
                  default: "Niveau scolaire",
                  ar: "المستوى الدراسي",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: [
                  {
                    value: "Primaire",
                    text: {
                      ar: "ابتدائي",
                    },
                  },
                  {
                    value: "Secondaire",
                    text: {
                      ar: "ثانوي",
                    },
                  },
                  {
                    value: "Universitaire",
                    text: {
                      ar: "جامعي",
                    },
                  },
                  {
                    value: "Formation professionnelle",
                    text: {
                      ar: "تكوين مهني",
                    },
                  },
                ],
                placeholder: "",
                allowClear: false,
                searchEnabled: false,
              },
              {
                type: "text",
                name: "investor_email",
                title: {
                  default: "Adresse e-mail",
                  ar: "البريد الإلكتروني",
                },
                hideNumber: true,
                enableIf: "{investor_email} empty",
                isRequired: true,
                errorLocation: "bottom",
                inputType: "email",
              },
              {
                type: "text",
                name: "investor_phone_number",
                startWithNewLine: false,
                title: {
                  default: "Numéro de téléphone",
                  ar: "رقم الهاتف",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                inputType: "tel",
              },
              {
                type: "dropdown",
                name: "investor_civility",
                title: {
                  default: "Genre",
                  ar: "الجنس",
                },
                hideNumber: true,
                enableIf: "{investor_civility} empty",
                isRequired: true,
                errorLocation: "bottom",
                choices: [
                  {
                    value: "Homme",
                    text: {
                      ar: "ذكر",
                    },
                  },
                  {
                    value: "Femme",
                    text: {
                      ar: "أنثى",
                    },
                  },
                ],
                placeholder: "",
                allowClear: false,
                searchEnabled: false,
              },
              {
                type: "text",
                name: "investor_birth_date",
                startWithNewLine: false,
                title: {
                  default: "Date de naissance",
                  ar: "تاريخ الولادة",
                },
                hideNumber: true,
                enableIf: "{investor_birth_date} empty",
                isRequired: true,
                errorLocation: "bottom",
                inputType: "date",
              },
              {
                type: "text",
                name: "investor_birth_place",
                title: {
                  default: "Lieu de naissance",
                  ar: "مكان الولادة",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "dropdown",
                name: "investor_nationality",
                startWithNewLine: false,
                title: {
                  default: "Nationalité",
                  ar: "الجنسية",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: [
                  {
                    value: "Tunisienne",
                    text: {
                      ar: "تونسية",
                    },
                  },
                  "Française",
                ],
                placeholder: "",
                allowClear: false,
                searchEnabled: false,
              },
              {
                type: "dropdown",
                name: "investor_residence_governorate",
                title: {
                  default: "Gouvernorat de résidence",
                  ar: "ولاية الإقامة",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: gouvernoratList[localStorage.getItem("language")],
                placeholder: "",
                allowClear: false,
              },
              {
                type: "dropdown",
                name: "investor_residence_delegation",
                startWithNewLine: false,
                title: {
                  default: "Délégation de résidence",
                  ar: "معتمدية الإقامة",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                placeholder: "",
                allowClear: false,
              },
              {
                type: "text",
                name: "investor_postal_code",
                title: {
                  default: "Code postal",
                  ar: "الترقيم البريدي",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "radiogroup",
                name: "investor_specific_needs",
                minWidth: "100px",
                startWithNewLine: false,
                title: {
                  default: "Personne à besoin spécifique",
                  ar: "احتياجات خاصة",
                },
                hideNumber: true,
                isRequired: true,
                choices: [
                  {
                    value: "Oui",
                    text: {
                      default: "Oui",
                      ar: "نعم",
                    },
                  },
                  {
                    value: "Non",
                    text: {
                      default: "Non",
                      ar: "لا",
                    },
                  },
                ],
                colCount: 5,
              },
              {
                type: "radiogroup",
                name: "investor_RNE",
                minWidth: "100px",
                title: {
                  default: "Quel est l'état actuel de votre entreprise ?",
                  ar: "ما هو الوضع الحالي لشركتك؟",
                },
                hideNumber: true,
                isRequired: true,
                choices: [
                  {
                    value:
                      "Réservation RNE uniquement (Entreprise en cours de création)",
                    text: {
                      default:
                        "Réservation RNE uniquement (Entreprise en cours de création)",
                      ar: "حجز RNE فقط (شركة قيد الإنشاء)",
                    },
                  },
                  {
                    value:
                      "Numéro de Patente disponible (Entreprise déjà créée)",
                    text: {
                      default:
                        "Numéro de Patente disponible (Entreprise déjà créée)",
                      ar: "رقم المعرف الجبائي متاح (شركة تم إنشاؤها بالفعل)",
                    },
                  },
                ],
                colCount: 2,
              },
              {
                type: "text",
                name: "investor_cin",
                visibleIf: "{investor_nationality} = 'Tunisienne'",
                startWithNewLine: false,
                title: {
                  default: "Numéro CIN",
                  ar: "رقم بطاقة التعريف الوطنية",
                },
                hideNumber: true,
                enableIf: "{investor_cin} empty",
                isRequired: true,
                errorLocation: "bottom",
                validators: [
                  {
                    type: "expression",
                  },
                ],
                maskType: "pattern",
                maskSettings: {
                  pattern: "99999999",
                },
              },
              {
                type: "text",
                name: "investor_passport",
                visibleIf: "{investor_nationality} <> 'Tunisienne'",
                startWithNewLine: false,
                title: {
                  default: "Numéro de passeport",
                  ar: "رقم جواز السفر",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "file",
                name: "investor_copie_cin",
                visibleIf: "{investor_nationality} = 'Tunisienne'",
                title: {
                  default: "Copie CIN",
                  ar: "نسخة رقمية من بطاقة التعريف الوطنية",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                filePlaceholder: "حمّل نسخة رقمية من بطاقة التعريف الوطنية",
              },
              {
                type: "file",
                name: "investor_copie_passeport",
                visibleIf: "{investor_nationality} <> 'Tunisienne'",
                startWithNewLine: false,
                title: {
                  default: "Copie Passeport",
                  ar: "نسخة رقمية من جواز السفر",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                filePlaceholder: "حمل نسخة من جواز السفر",
              },
              {
                type: "file",
                name: "investor_copie_cv",
                startWithNewLine: false,
                title: {
                  default: "Copie CV",
                  ar: " نسخة من السيرة الذاتية",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                filePlaceholder: "حمّل السيرة الذاتية للمستثمر",
              },
            ],
          },
        ],
      },
      {
        name: "page9",
        visibleIf: "{inverstor_type} = 'Personne morale'",
        title: {
          default: "Données relatives à l'établissement (Personne Morale)",
          ar: "الشخص المعنوي",
        },
        elements: [
          {
            type: "panel",
            name: "panel5",
            elements: [
              {
                type: "text",
                name: "legal_entity_reservation_number",
                title: {
                  default: "Numéro de réservation",
                  ar: "رقم الحجز",
                },
                visibleIf:
                  "{investor_RNE} = 'Réservation RNE uniquement (Entreprise en cours de création)'",

                hideNumber: true,
                isRequired: true,
                startWithNewLine: false,

                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "legal_entity_tax_number",
                visibleIf:
                  "{investor_RNE} = 'Numéro de Patente disponible (Entreprise déjà créée)'",
                title: {
                  default: "Numéro d’identifiant fiscal / RNE",
                  ar: "المعرف الجبائي",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "legal_entity_official_name",
                startWithNewLine: false,
                title: {
                  default: "Dénomination officielle",
                  ar: "التسمية الرسمية",
                },
                hideNumber: true,
                enableIf: "{legal_entity_official_name} empty",
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "dropdown",
                name: "legal_entity_legal_form",
                title: {
                  default: "Forme Juridique",
                  ar: "الشكل القانوني",
                },
                hideNumber: true,
                errorLocation: "bottom",
                choices: ["SARL", "SUARL", "SA"],
              },
              {
                type: "text",
                name: "legal_entity_head_office_address",
                startWithNewLine: false,
                title: {
                  default: "Adresse du siège social",
                  ar: "عنوان المقر الرئيسي",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "legal_entity_reservation_expiration_date",
                title: {
                  default: "Date d’expiration de la réservation",
                  ar: "تاريخ انتهاء الحجز",
                },
                hideNumber: true,
                enableIf: "{legal_entity_reservation_expiration_date} empty",
                visibleIf:
                  "{investor_RNE} = 'Réservation RNE uniquement (Entreprise en cours de création)'",
                isRequired: true,
                errorLocation: "bottom",
                inputType: "date",
              },
              {
                type: "text",
                name: "legal_entity_sector_activity",
                startWithNewLine: false,
                title: {
                  default: "Secteur d’activité",
                  ar: "قطاع النشاط",
                },
                hideNumber: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "legal_entity_cnss_registration_number",
                startWithNewLine: false,
                title: {
                  default: "Numéro de matricule CNSS",
                  ar: "رقم الانخراط في الصندوق الوطني للضمان الاجتماعي",
                },
                visibleIf:
                  "{investor_RNE} = 'Numéro de Patente disponible (Entreprise déjà créée)'",
                hideNumber: true,
                errorLocation: "bottom",
              },
              {
                type: "dropdown",
                name: "legal_entity_governorate",
                title: {
                  default: "Gouvernorat",
                  ar: "الولاية",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: gouvernoratList[localStorage.getItem("language")],
              },
              {
                type: "dropdown",
                name: "legal_entity_delegation",
                startWithNewLine: false,
                title: {
                  default: "Délégation",
                  ar: "الجنسية",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                placeholder: "",
                allowClear: false,
                searchEnabled: false,
              },
              {
                type: "text",
                name: "legal_entity_phone_number",
                title: {
                  default: "Numéro de téléphone",
                  ar: "رقم الهاتف",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                inputType: "tel",
                min: 1,
              },
            ],
          },
        ],
      },
      {
        name: "page2",
        title: {
          default: "Données de l’établissement de formation",
          ar: "معطيات هيكل التكوين الخاص",
        },
        elements: [
          {
            type: "panel",
            name: "panel1",
            elements: [
              {
                type: "text",
                name: "establishment_data_reservation_number",
                visibleIf:
                  "{investor_RNE} = 'Réservation RNE uniquement (Entreprise en cours de création)'",
                title: {
                  default: "Numéro de réservation",
                  ar: "رقم الحجز",
                },
                hideNumber: true,
                enableIf: "{inverstor_type} <> 'Personne morale'",
                defaultValueExpression: "{legal_entity_reservation_number}",
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "establishment_data_trade_name",
                startWithNewLine: false,
                title: {
                  default: "Dénomination commerciale",
                  ar: "الإسم التجاري",
                },
                hideNumber: true,
                enableIf: "{inverstor_type} <> 'Personne morale'",
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "establishment_data_cnss_number",
                visibleIf: "{investor_RNE} = 'Numéro de patente'",
                title: {
                  default: "Numéro CNSS",
                  ar: "رقم الانخراط في الصندوق الوطني للضمان الاجتماعي",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "establishment_data_tax_number",
                visibleIf:
                  "{investor_RNE} = 'Numéro de Patente disponible (Entreprise déjà créée)'",
                title: {
                  default: "Numéro d’identifiant fiscal / RNE",
                  ar: "المعرف الجبائي",
                },
                hideNumber: true,
                enableIf: "{inverstor_type} <> 'Personne morale'",
                defaultValueExpression: "{legal_entity_tax_number}",
                isRequired: true,
                errorLocation: "bottom",
              },

              {
                type: "text",
                name: "establishment_data_cnss_number",
                visibleIf:
                  "{investor_RNE} = 'Numéro de Patente disponible (Entreprise déjà créée)'",
                startWithNewLine: true,
                title: {
                  default: "Numéro CNSS",
                  ar: "رقم الانخراط في الصندوق الوطني للضمان الاجتماعي",
                },
                hideNumber: true,
                isRequired: true,

                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "establishment_data_phone_number_1",
                title: {
                  default: "Numéro de téléphone 1",
                  ar: "رقم الهاتف 1",
                },
                hideNumber: true,
                isRequired: true,
              },
              {
                type: "text",
                name: "establishment_data_phone_number_2",
                startWithNewLine: false,
                title: {
                  default: "Numéro de téléphone 2",
                  ar: "رقم الهاتف 2",
                },
                hideNumber: true,
              },
              {
                type: "text",
                name: "establishment_data_fax_number",
                startWithNewLine: false,
                title: {
                  default: "Numéro de fax",
                  ar: "رقم الفاكس",
                },
                hideNumber: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "establishment_data_email",
                title: {
                  default: "Adresse e-mail",
                  ar: "البريد الإلكتروني",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "establishment_data_address",
                startWithNewLine: false,
                title: {
                  default: "Adresse de l’établissement",
                  ar: "عنوان الهيكل",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "dropdown",
                name: "establishment_data_training_type",
                startWithNewLine: false,
                title: {
                  default: "Nature de formation",
                  ar: "طبيعة التكوين",
                },
                hideNumber: true,
                isRequired: true,
                choices: [
                  {
                    value: "Initiale",
                    text: {
                      ar: "أولية",
                    },
                  },
                  {
                    value: "Continue",
                    text: {
                      ar: "مستمرة",
                    },
                  },
                  {
                    value: "Initiale et continue",
                    text: {
                      ar: "أولية ومستمرة",
                    },
                  },
                ],
                placeholder: "",
                allowClear: false,
              },
              {
                type: "dropdown",
                name: "establishment_data_governorate",
                title: {
                  default: "Gouvernorat",
                  ar: "الولاية",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: gouvernoratList[localStorage.getItem("language")],
                placeholder: "",
                allowClear: false,
              },
              {
                type: "dropdown",
                name: "establishment_data_delegation",
                startWithNewLine: false,
                title: {
                  default: "Délégation",
                  ar: "المعتمدية",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                placeholder: "",
                allowClear: false,
              },
              {
                type: "text",
                name: "establishment_data_postal_code",
                startWithNewLine: false,
                title: {
                  default: "Code postal",
                  ar: "الترقيم البريدي",
                },
                hideNumber: true,
                isRequired: true,
              },
              {
                type: "file",
                name: "establishment_data_logo",
                title: {
                  default: "Logo",
                  ar: "الشعار",
                },
                hideNumber: true,
                errorLocation: "bottom",
                acceptedTypes: ".jpg,.jpeg,.png",
                needConfirmRemoveFile: true,
                filePlaceholder: "Télécharger le logo",
              },
              {
                type: "file",
                name: "establishment_data_rne",
                visibleIf:
                  "{investor_RNE} = 'Réservation RNE uniquement (Entreprise en cours de création)'",
                title: {
                  default: "Décharge de la réservation RNE",
                  ar: "الشعار",
                },
                startWithNewLine: false,
                hideNumber: true,
                errorLocation: "bottom",
                acceptedTypes: ".jpg,.jpeg,.png",
                needConfirmRemoveFile: true,
                filePlaceholder:
                  "Télécharger la décharge de la réservation RNE",
              },
              {
                type: "file",
                name: "establishment_data_pattente",
                visibleIf:
                  "{investor_RNE} = 'Numéro de Patente disponible (Entreprise déjà créée)'",
                title: {
                  default: "Copie de la Pattente",
                  ar: "سخة رقمية من المعرف الجبائي",
                },
                hideNumber: true,
                errorLocation: "bottom",
                acceptedTypes: ".jpg,.jpeg,.png",
                needConfirmRemoveFile: true,
                filePlaceholder: "Téléchargez une copie de la patente",
              },
            ],
          },
        ],
      },
      {
        name: "Données de Directeur de l’Établissement",
        title: {
          default: "Données de Directeur de l’Établissement",
          ar: "بيانات مديرالهيكل",
        },
        elements: [
          {
            type: "panel",
            name: "panel2",
            elements: [
              {
                type: "dropdown",
                name: "establishment_director_data_identity",
                title: {
                  default: "Identité",
                  ar: "الهوية",
                },
                hideNumber: true,
                isRequired: true,
                choices: [
                  {
                    value: "other",
                    text: {
                      default: "Directeur Autre que l’Investisseur",
                      ar: "مدير غير المستثمر",
                    },
                  },
                  {
                    value: "self",
                    text: {
                      default: "Investisseur lui-même",
                      ar: "المستثمر نفسه",
                    },
                  },
                ],
              },
              {
                type: "text",
                name: "establishment_director_data_last_name",
                startWithNewLine: false,
                title: {
                  default: "Nom",
                  ar: "اللقب",
                },
                hideNumber: true,
                isRequired: true,
              },
              {
                type: "text",
                name: "establishment_director_data_first_name",
                title: {
                  default: "Prénom",
                  ar: "الاسم",
                },
                hideNumber: true,
                isRequired: true,
              },
              {
                type: "text",
                name: "establishment_director_data_civility",
                startWithNewLine: false,
                title: {
                  default: "Genre",
                  ar: "الجنس",
                },
                hideNumber: true,
                isRequired: true,
              },
              {
                type: "dropdown",
                name: "establishment_director_data_nationality",
                title: {
                  default: "Nationalité",
                  ar: "الجنسية",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: ["Tunisienne", "Française"],
                placeholder: "",
                allowClear: false,
                searchEnabled: false,
              },
              {
                type: "text",
                name: "establishment_director_data_birth_date",
                startWithNewLine: false,
                title: {
                  default: "Date de naissance",
                  ar: "تاريخ الولادة",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "establishment_director_data_birth_place",
                title: {
                  default: "Lieu de naissance",
                  ar: "مكان الولادة",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "establishment_director_data_specific_needs",
                startWithNewLine: false,
                title: {
                  default: "Personne à besoin spécifique",
                  ar: "احتياجات خاصة",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "establishment_director_data_ministerial_auth_date",
                visibleIf:
                  "{establishment_director_data_nationality} <> 'Tunisienne'",
                title: {
                  default: "Date d’autorisation de ministre",
                  ar: "تاريخ ترخيص الوزير",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                inputType: "date",
              },
              {
                type: "text",
                name: "establishment_director_data_cin_number",
                visibleIf:
                  "{establishment_director_data_nationality} = 'Tunisienne'",
                startWithNewLine: false,
                title: {
                  default: "Numéro CIN",
                  ar: "رقم بطاقة التعريف الوطنية",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                maskType: "pattern",
                maskSettings: {
                  pattern: "99999999",
                },
              },
              {
                type: "text",
                name: "question45",
                visibleIf:
                  "{establishment_director_data_nationality} <> 'Tunisienne'",
                startWithNewLine: false,
                title: {
                  default: "Numéro de passeport",
                  ar: "رقم جواز السفر",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "file",
                name: "question15",
                visibleIf:
                  "{establishment_director_data_nationality} <> 'Tunisienne'",
                title: {
                  default: "copie de l’autorisation",
                  ar: "تاريخ ترخيص الوزير",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "file",
                name: "establishment_director_data_passeport_copie",
                visibleIf:
                  "{establishment_director_data_nationality} <> 'Tunisienne'",
                startWithNewLine: false,
                title: {
                  default: "Copie passeport",
                  ar: "ملف المدير (السيرة الذاتية وغيرها)",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                allowMultiple: true,
                fileOrPhotoPlaceholder: "Télécharger la copie du passeport.",
              },
              {
                type: "text",
                name: "establishment_director_data_address",
                title: {
                  default: "Adresse",
                  ar: "العنوان",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "dropdown",
                name: "establishment_director_data_governorate",
                startWithNewLine: false,
                title: {
                  default: "Gouvernorat",
                  ar: "الولاية",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: gouvernoratList[localStorage.getItem("language")],
                placeholder: "",
                allowClear: false,
              },
              {
                type: "dropdown",
                name: "establishment_director_data_delegation",
                title: {
                  default: "Délégation",
                  ar: "المعتمدية",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                placeholder: "",
                allowClear: false,
              },
              {
                type: "text",
                name: "establishment_director_data_email",
                startWithNewLine: false,
                title: {
                  default: "Adresse e-mail",
                  ar: "البريد الإلكتروني",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                inputType: "email",
              },
              {
                type: "text",
                name: "establishment_director_data_phone_number",
                title: {
                  default: "Numéro de téléphone",
                  ar: "رقم الهاتف",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                inputType: "tel",
              },
              {
                type: "text",
                name: "establishment_director_data_fax_number",
                startWithNewLine: false,
                title: {
                  default: "Numéro de fax",
                  ar: "رقم الفاكس",
                },
                hideNumber: true,
                errorLocation: "bottom",
                inputType: "tel",
              },
              {
                type: "dropdown",
                name: "establishment_director_data_education_level",
                title: {
                  default: "Niveau scolaire",
                  ar: "المستوى الدراسي",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: [
                  {
                    value: "Primaire",
                    text: {
                      ar: "ابتدائي",
                    },
                  },
                  {
                    value: "Secondaire",
                    text: {
                      ar: "ثانوي",
                    },
                  },
                  {
                    value: "Universitaire",
                    text: {
                      ar: "جامعي",
                    },
                  },
                  {
                    value: "Formation professionnelle",
                    text: {
                      ar: "تكوين مهني",
                    },
                  },
                ],
                placeholder: "",
                allowClear: false,
                searchEnabled: false,
              },
              {
                type: "text",
                name: "establishment_director_data_experience_years",
                startWithNewLine: false,
                title: {
                  default: "Nombre d’années d’expérience",
                  ar: "عدد سنوات الخبرة",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                inputType: "number",
                min: 0,
              },
              {
                type: "file",
                name: "establishment_director_data_director_folder",
                title: {
                  default: "Dossier du directeur (CV et autres)",
                  ar: "ملف المدير (السيرة الذاتية وغيرها)",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                allowMultiple: true,
                fileOrPhotoPlaceholder:
                  "Glissez-déposez ou sélectionnez un fichier à télécharger ou prenez une photo à l'aide de l'appareil photo.",
                filePlaceholder:
                  "Glissez-déposez un fichier ici ou cliquez sur le bouton ci-dessous pour sélectionner un fichier à télécharger.",
              },
              {
                type: "file",
                name: "establishment_director_data_cin_copie",
                visibleIf:
                  "{establishment_director_data_nationality} = 'Tunisienne'",
                startWithNewLine: false,
                title: {
                  default: "Copie CIN",
                  ar: "ملف المدير (السيرة الذاتية وغيرها)",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                allowMultiple: true,
                fileOrPhotoPlaceholder: "Télécharger la copie du CIN.",
              },
            ],
          },
        ],
      },
      {
        name: "page4",
        title: {
          default: "Données sur la nature de formation",
          ar: "بيانات حول طبيعة التكوين",
        },
        elements: [
          {
            type: "panel",
            name: "panel_training",
            elements: [
              {
                type: "paneldynamic",
                name: "training_nature_data",
                title: {
                  default: "Données sur la nature de formation",
                  ar: "بيانات حول طبيعة التكوين",
                },
                hideNumber: true,

                isRequired: true,
                templateElements: [
                  {
                    type: "panel",
                    name: "training_type_data",
                    title: {
                      default: "Formation",
                      ar: "التكوين",
                    },
                    state: "collapsed",
                    elements: [
                      {
                        type: "dropdown",
                        name: "training_type_data_nature",
                        title: {
                          default: "Nature",
                          ar: "الطبيعة",
                        },
                        hideNumber: true,
                        enableIf:
                          "{establishment_data_training_type} = 'Initiale et continue'",
                        defaultValueExpression:
                          "{establishment_data_training_type}",
                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "Initiale",
                            text: {
                              ar: "أولية",
                            },
                          },
                          {
                            value: "Continue",
                            text: {
                              ar: "مستمرة",
                            },
                          },
                        ],
                        placeholder: "",
                        allowClear: false,
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_type",
                        visibleIf:
                          "{panel.training_type_data_nature} = 'Initiale'",
                        startWithNewLine: false,
                        title: {
                          default: "Type",
                          ar: "النوع",
                        },
                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "Homologuée",
                            text: {
                              default: "Homologuée",
                              ar: "معترف بها",
                            },
                          },
                          {
                            value: "Non homologuée",
                            text: {
                              ar: "غير معترف بها",
                            },
                          },
                        ],
                        placeholder: "",
                        allowClear: false,
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_sector",
                        title: {
                          default: "Secteur",
                          ar: "القطاع",
                        },
                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "Item 1",
                            text: "SecteurA",
                          },
                          {
                            value: "Item 2",
                            text: "SecteurB",
                          },
                          {
                            value: "Item 3",
                            text: "SecteurC",
                          },
                        ],
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_sub_sector",
                        startWithNewLine: false,
                        title: {
                          default: "Sous-secteur",
                          ar: "القطاع الفرعي",
                        },
                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "Item 1",
                            text: "SecteurA",
                          },
                          {
                            value: "Item 2",
                            text: "SecteurB",
                          },
                          {
                            value: "Item 3",
                            text: "SecteurC",
                          },
                        ],
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_specialty",
                        visibleIf:
                          "{panel.training_type_data_type} allof ['Homologuée']",
                        title: {
                          default: "Spécialité",
                          ar: "التخصص",
                        },
                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "Item 1",
                            text: {
                              default: "Ouvrier dans l'entretien des jardins",
                              ar: "عامل  في  العناية بالحدائق",
                            },
                          },
                          {
                            value: "Item 2",
                            text: {
                              default:
                                "Ouvrier dans la production des fleurs coupées",
                              ar: "عامل في انتاج الزهور",
                            },
                          },
                          {
                            value: "Item 3",
                            text: {
                              default: "Ouvrier en cultures ornementales",
                              ar: "عامل في زراعة نباتات الزينة",
                            },
                          },
                          {
                            value: "Item 4",
                            text: {
                              default:
                                "Ouvrier en cultures maraîchères de plein champ",
                              ar: "عامل في زراعة الخضروات الحقلية",
                            },
                          },
                          {
                            value: "Item 5",
                            text: {
                              default:
                                "Ouvrier en cultures maraîchères protégées",
                              ar: "عامل في زراعة الخضروات المحمية",
                            },
                          },
                        ],
                        allowClear: false,
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_diploma",
                        visibleIf:
                          "{panel.training_type_data_type} allof ['Homologuée']",
                        startWithNewLine: false,
                        title: {
                          default: "Diplôme",
                          ar: "الشهادة",
                        },
                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "Item 1",
                            text: "Diplome1",
                          },
                          {
                            value: "Item 2",
                            text: "Diplome2",
                          },
                          {
                            value: "Item 3",
                            text: "Diplome3",
                          },
                        ],
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_required_level",
                        visibleIf:
                          "{panel.training_type_data_type} allof ['Homologuée']",
                        startWithNewLine: false,
                        title: {
                          default: "Niveau requis",
                          ar: "المستوى المطلوب",
                        },
                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "Item 1",
                            text: "Niveau1",
                          },
                          {
                            value: "Item 2",
                            text: "Niveau2",
                          },
                          {
                            value: "Item 3",
                            text: "Niveau3",
                          },
                        ],
                      },
                      {
                        type: "text",
                        name: "training_type_data_duration_hour",
                        title: {
                          default: "Durée en (H)",
                          ar: "المدة بالساعات",
                        },
                        isRequired: true,
                        errorLocation: "bottom",
                        inputType: "number",
                        min: 0,
                      },
                      {
                        type: "text",
                        name: "training_type_data_duration_month",
                        startWithNewLine: false,
                        title: {
                          default: "Durée en (mois)",
                          ar: "المدة بالأشهر",
                        },
                        isRequired: true,
                        errorLocation: "bottom",
                        inputType: "number",
                        min: 0,
                      },
                      {
                        type: "text",
                        name: "training_type_data_duration_minimum",
                        visibleIf:
                          "{panel.training_type_data_nature} <> 'Continue' and {panel.training_type_data_type} <> 'Non homologuée' ",
                        startWithNewLine: false,
                        title: {
                          default: "Durée minimale",
                          ar: "المدة الدنيا",
                        },
                        isRequired: true,
                        errorLocation: "bottom",
                        inputType: "number",
                        min: 0,
                      },
                      {
                        type: "text",
                        name: "training_type_data_domain",
                        visibleIf:
                          "{panel.training_type_data_type} = 'Non homologuée' or {panel.training_type_data_nature} = 'Continue'",
                        title: {
                          default: "Domaine",
                          ar: "المجالات",
                        },
                        hideNumber: true,
                        isRequired: true,
                        errorLocation: "bottom",
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_mode",
                        maxWidth: "50%",
                        startWithNewLine: false,
                        title: {
                          default: "Mode de formation",
                          ar: "طريقة التكوين",
                        },
                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "Présentielle",
                            text: {
                              default: "Présentielle",
                              ar: "حضورية",
                            },
                          },
                          {
                            value: "À distance",
                            text: {
                              default: "À distance",
                              ar: "عن بعد",
                            },
                          },
                          {
                            value: "En alternance",
                            text: {
                              default: "En alternance",
                              ar: "بالتناوب",
                            },
                          },
                        ],
                        placeholder: "",
                        allowClear: false,
                        searchEnabled: false,
                      },
                    ],
                  },
                ],
                panelCount: 1,
                minPanelCount: 1,
                panelAddText: {
                  default: "Ajouter une formation",
                  ar: "إضافة تكوين",
                },
                panelRemoveText: "Supprimer",
              },
            ],
          },
        ],
      },
      {
        name: "page5",
        title: {
          default: "Données des Formateurs",
          ar: "بيانات المكونين",
        },
        elements: [
          {
            type: "panel",
            name: "trainer",
            elements: [
              {
                type: "paneldynamic",
                name: "trainer_data",
                title: "Données des Formateurs",
                hideNumber: true,

                templateElements: [
                  {
                    type: "panel",
                    name: "trainer_obj",
                    title: "Formateur",
                    state: "collapsed",
                    elements: [
                      {
                        type: "dropdown",
                        name: "trainer_data_nationality",
                        title: {
                          default: "Nationalité",
                          ar: "الجنسية",
                        },
                        hideNumber: true,
                        isRequired: true,
                        choices: ["Tunisienne", "Française"],
                        allowClear: false,
                      },
                      {
                        type: "text",
                        name: "trainer_data_passport",
                        visibleIf:
                          "{panel.trainer_data_nationality} <> 'Tunisienne'",
                        startWithNewLine: false,
                        title: {
                          default: "Numéro de passeport",
                          ar: "رقم جواز السفر",
                        },
                        hideNumber: true,
                        isRequired: true,
                      },
                      {
                        type: "text",
                        name: "trainer_data_cin_number",
                        visibleIf:
                          "{panel.trainer_data_nationality} = 'Tunisienne'",
                        startWithNewLine: false,
                        title: {
                          default: "Numéro CIN",
                          ar: "رقم بطاقة التعريف الوطنية",
                        },
                        hideNumber: true,
                        isRequired: true,
                        maskType: "pattern",
                        maskSettings: {
                          pattern: "99999999",
                        },
                      },
                      {
                        type: "text",
                        name: "trainer_data_last_name",
                        title: {
                          default: "Nom",
                          ar: "اللقب",
                        },
                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                      },
                      {
                        type: "text",
                        name: "trainer_data_first_name",
                        startWithNewLine: false,
                        title: {
                          default: "Prénom",
                          ar: "الاسم",
                        },
                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                      },
                      {
                        type: "dropdown",
                        name: "trainer_data_civility",
                        title: {
                          default: "Genre",
                          ar: "الجنس",
                        },
                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                        choices: ["Homme", "Femme"],
                      },
                      {
                        type: "text",
                        name: "trainer_data_birth_date",
                        startWithNewLine: false,
                        title: {
                          default: "Date de naissance",
                          ar: "تاريخ الولادة",
                        },
                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                        inputType: "date",
                      },
                      {
                        type: "text",
                        name: "trainer_data_birth_place",
                        title: {
                          default: "Lieu de naissance",
                          ar: "مكان الولادة",
                        },
                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                      },
                      {
                        type: "text",
                        name: "trainer_data_address",
                        startWithNewLine: false,
                        title: {
                          default: "Adresse",
                          ar: "العنوان",
                        },
                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                      },
                      {
                        type: "dropdown",
                        name: "trainer_data_governorate",
                        title: {
                          default: "Gouvernorat",
                          ar: "الولاية",
                        },
                        hideNumber: true,
                        isRequired: true,
                        errorLocation: "bottom",
                        readOnly: true,
                        choices:
                          gouvernoratList[localStorage.getItem("language")],
                        placeholder: "",
                        allowClear: false,
                      },
                      {
                        type: "dropdown",
                        name: "trainer_data_delegation",
                        startWithNewLine: false,
                        title: {
                          default: "Délégation",
                          ar: "المعتمدية",
                        },
                        hideNumber: true,
                        isRequired: true,
                        errorLocation: "bottom",
                        readOnly: true,
                        placeholder: "",
                        allowClear: false,
                      },
                      {
                        type: "text",
                        name: "trainer_data_postal_code",
                        title: {
                          default: "Code postal",
                          ar: "الترقيم البريدي",
                        },
                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                      },
                      {
                        type: "text",
                        name: "trainer_data_email",
                        startWithNewLine: false,
                        title: {
                          default: "Adresse e-mail",
                          ar: "البريد الإلكتروني",
                        },
                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                        inputType: "email",
                      },
                      {
                        type: "text",
                        name: "trainer_data_phone_number",
                        title: {
                          default: "Numéro de téléphone",
                          ar: "رقم الهاتف",
                        },
                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                      },
                      {
                        type: "dropdown",
                        name: "trainer_data_education_level",
                        startWithNewLine: false,
                        title: {
                          default: "Niveau scolaire",
                          ar: "المستوى التعليمي",
                        },
                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                        choices: ["Primaire", "Secondaire", "Universitaire"],
                      },
                      {
                        type: "text",
                        name: "trainer_data_specialty_domain",
                        title: {
                          default: "Domaines de spécialité",
                          ar: "مجالات التخصص",
                        },
                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                      },
                      {
                        type: "dropdown",
                        name: "trainer_data_situation",
                        maxWidth: "50%",
                        startWithNewLine: false,
                        title: {
                          default: "Situation",
                          ar: "الوضعية",
                        },
                        hideNumber: true,
                        isRequired: true,
                        choices: [
                          {
                            value: "Permanent",
                            text: {
                              ar: "قار",
                              default: "Permanent",
                            },
                          },
                          {
                            value: "Vocataire",
                            text: {
                              ar: "غير قار",
                              default: "Vocataire",
                            },
                          },
                        ],
                        allowClear: false,
                      },
                      {
                        type: "radiogroup",
                        name: "trainer_data_specific_needs",
                        title: {
                          default: "Personne à besoin spécifique",
                          ar: "احتياجات خاصة",
                        },
                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                        choices: [
                          {
                            value: "Oui",
                            text: {
                              default: "Oui",
                              ar: "نعم",
                            },
                          },
                          {
                            value: "Non",
                            text: {
                              default: "Non",
                              ar: "لا",
                            },
                          },
                        ],
                        colCount: 5,
                      },
                      {
                        type: "file",
                        name: "trainer_data_folder",
                        startWithNewLine: false,
                        title: {
                          default: "Dossier",
                          ar: "الملف",
                        },
                        isRequired: true,
                        filePlaceholder: "Télécharger le dossier du formateur.",
                      },
                      {
                        type: "file",
                        name: "trainer_data_cin",
                        visibleIf:
                          "{panel.trainer_data_nationality} = 'Tunisienne'",
                        startWithNewLine: false,
                        title: {
                          default: "Copie CIN",
                          ar: "الملف",
                        },
                        isRequired: true,
                        visibleIf:
                          "{panel.trainer_data_nationality} = 'Tunisienne'",
                        filePlaceholder: "Télécharger le cin du formateur.",
                      },
                      {
                        type: "file",
                        name: "trainer_data_passeport",
                        visibleIf:
                          "{panel.trainer_data_nationality} <> 'Tunisienne'",
                        startWithNewLine: false,
                        title: {
                          default: "Copie passeport",
                          ar: "passeport",
                        },
                        isRequired: true,
                      },
                      {
                        type: "expression",
                        name: "isVisible",
                        visible: false,
                        title: {
                          default: "isVisible",
                        },
                      },
                    ],
                  },
                ],
                panelCount: 1,
                minPanelCount: 1,
                panelAddText: "Ajouter un formateur",
                panelRemoveText: "Supprmier",
              },
            ],
          },
        ],
      },
      {
        name: "page6",
        title: {
          default: "Données des Espaces",
          ar: "بيانات المساحات",
        },
        elements: [
          {
            type: "panel",
            name: "panel7",
            elements: [
              {
                type: "paneldynamic",
                name: "space_data",
                title: {
                  default: "Données des Espaces",
                  ar: "بيانات المساحات",
                },
                hideNumber: true,

                templateElements: [
                  {
                    type: "panel",
                    name: "space_obj",
                    title: {
                      default: "Espace",
                      ar: "مساحة",
                    },
                    state: "collapsed",
                    elements: [
                      {
                        type: "dropdown",
                        name: "space_data_type",
                        title: {
                          default: "Type",
                          ar: "النوع",
                        },
                        hideNumber: true,
                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "Administration",
                            text: {
                              default: "Administration",
                              ar: "إدارة",
                            },
                          },
                          {
                            value: "Espace commun",
                            text: {
                              ar: "فضاء مشترك",
                            },
                          },
                          {
                            value: "Salle de classe",
                            text: {
                              ar: "قاعة تدريس",
                            },
                          },
                          {
                            value: "Atelier",
                            text: {
                              ar: "ورشة",
                            },
                          },
                          {
                            value: "Laboratoire",
                            text: {
                              ar: "مخبر",
                            },
                          },
                        ],
                        showOtherItem: true,
                        otherText: {
                          default: "Autre",
                          ar: "نوع آخر",
                        },
                        placeholder: "",
                        allowClear: false,
                      },
                      {
                        type: "text",
                        name: "space_data_label",
                        startWithNewLine: false,
                        title: {
                          default: "Libellé",
                          ar: "الوصف",
                        },
                        isRequired: true,
                        errorLocation: "bottom",
                      },
                      {
                        type: "text",
                        name: "space_data_surface_area",
                        title: {
                          default: "Superficie en m²",
                          ar: "المساحة بالمتر المربع",
                        },
                        isRequired: true,
                        errorLocation: "bottom",
                        inputType: "number",
                        min: 0,
                      },
                      {
                        type: "text",
                        name: "space_data_capacity",
                        startWithNewLine: false,
                        title: {
                          default: "Capacité en personne",
                          ar: "طاقة الإستيعاب للأشخاص",
                        },
                        isRequired: true,
                        errorLocation: "bottom",
                        inputType: "number",
                        min: 0,
                      },
                    ],
                  },
                ],
                panelCount: 1,
                minPanelCount: 1,
                panelAddText: {
                  default: "Ajouter un espace",
                  ar: "إضافة مساحة",
                },
              },
            ],
          },
        ],
      },
      {
        name: "page7",
        title: {
          default: "Données des équipements",
          ar: "بيانات المساحات",
        },

        elements: [
          {
            type: "panel",
            name: "equipmets",
            elements: [
              {
                type: "paneldynamic",
                name: "equipments_data",
                title: {
                  default: "Données des équipements",
                  ar: "بيانات المساحات",
                },
                hideNumber: true,

                isRequired: true,
                templateElements: [
                  {
                    type: "panel",
                    name: "equipement_obj",
                    title: {
                      default: "Équipements",
                      ar: "مساحة",
                    },
                    state: "collapsed",
                    elements: [
                      {
                        type: "text",
                        name: "equipment_label",
                        title: {
                          default: "Libellé",
                          ar: "الوصف",
                        },
                        isRequired: true,
                        errorLocation: "bottom",
                      },
                      {
                        type: "text",
                        name: "equipment_number",
                        startWithNewLine: false,
                        title: {
                          default: "Nombre",
                          ar: "المساحة بالمتر المربع",
                        },
                        isRequired: true,
                        errorLocation: "bottom",
                        inputType: "number",
                        min: 1,
                      },
                      {
                        type: "file",
                        name: "equipment_tech_file",
                        title: {
                          default: "Fiche technique",
                          ar: "طاقة الإستيعاب للأشخاص",
                        },
                        isRequired: true,
                        errorLocation: "bottom",
                        allowMultiple: true,
                      },
                    ],
                  },
                ],
                panelCount: 1,
                minPanelCount: 1,
                panelAddText: {
                  default: "Ajouter un équipement",
                  ar: "إضافة مساحة",
                },

                // renderMode: "progressTop",
                // displayMode: "carousel",
              },
            ],
          },
        ],
      },
      {
        name: "page8",
        title: {
          default: "Données du personnel d'encadrement et de gestion",
          ar: "بيانات أعوان الإحاطة والتأطير",
        },
        elements: [
          {
            type: "paneldynamic",
            name: "management_data",
            state: "collapsed",
            title: {
              default: "Données du personnel d'encadrement et de gestion",
              ar: "بيانات أعوان الإحاطة والتأطير",
            },
            hideNumber: true,
            templateElements: [
              {
                type: "dropdown",
                name: "management_data_staff_category",
                title: {
                  default: "Catégorie",
                  ar: "الفئة",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: [
                  {
                    value: "Encadrement",
                    text: {
                      default: "Encadrement",
                      ar: "التأطير",
                    },
                  },
                  "Gestion",
                ],
                placeholder: "",
                allowClear: false,
              },
              {
                type: "text",
                name: "management_data_staff_email",
                startWithNewLine: false,
                title: {
                  default: "Adresse e-mail",
                  ar: "البريد الإلكتروني",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                inputType: "email",
              },
              {
                type: "dropdown",
                name: "management_data_staff_situation",
                title: {
                  default: "Situation",
                  ar: "الوضعية",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: [
                  {
                    value: "Permanent",
                    text: {
                      ar: "قار",
                      default: "Permanent",
                    },
                  },
                  {
                    value: "Non permanent",
                    text: {
                      ar: "غير قار",
                      default: "Non permanent",
                    },
                  },
                ],
                placeholder: "",
                allowClear: false,
              },
              {
                type: "text",
                name: "management_data_staff_last_name",
                startWithNewLine: false,
                title: {
                  default: "Nom",
                  ar: "اللقب",
                },
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "management_data_staff_first_name",
                title: {
                  default: "Prénom",
                  ar: "الاسم",
                },
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "dropdown",
                name: "management_data_staff_civility",
                startWithNewLine: false,
                title: {
                  default: "Genre",
                  ar: "الجنس",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: [
                  {
                    value: "Homme",
                    text: {
                      ar: "ذكر",
                      default: "Masculin",
                    },
                  },
                  {
                    value: "Femme",
                    text: {
                      ar: "أنثى",
                      default: "Féminin",
                    },
                  },
                ],
                placeholder: "",
                allowClear: false,
                searchEnabled: false,
              },
              {
                type: "dropdown",
                name: "management_data_staff_nationality",
                title: {
                  default: "Nationalité",
                  ar: "الجنسية",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: ["Tunisienne", "Française"],
                placeholder: "",
                allowClear: false,
                searchEnabled: false,
              },
              {
                type: "text",
                name: "management_data_staff_birth_date",
                startWithNewLine: false,
                title: {
                  default: "Date de naissance",
                  ar: "تاريخ الولادة",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                inputType: "date",
              },
              {
                type: "text",
                name: "management_data_staff_residence_address",
                title: {
                  default: "Adresse de résidence",
                  ar: "عنوان الإقامة",
                },
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "dropdown",
                name: "management_data_staff_residence_governorate",
                startWithNewLine: false,
                title: {
                  default: "Gouvernorat de résidence",
                  ar: "ولاية الإقامة",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: gouvernoratList[localStorage.getItem("language")],
                placeholder: "",
                allowClear: false,
              },
              {
                type: "dropdown",
                name: "management_data_staff_residence_delegation",
                title: {
                  default: "Délégation",
                  ar: "معتمدية الإقامة",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                placeholder: "",
                allowClear: false,
              },
              {
                type: "dropdown",
                name: "management_data_staff_education_level",
                startWithNewLine: false,
                title: {
                  default: "Niveau scolaire",
                  ar: "المستوى التعليمي",
                },
                isRequired: true,
                errorLocation: "bottom",
                choices: [
                  {
                    value: "Baccalauréat",
                    text: {
                      ar: "البكالوريا",
                      default: "Baccalauréat",
                    },
                  },
                  {
                    value: "Licence",
                    text: {
                      default: "Licence",
                      ar: "الإجازة",
                    },
                  },
                  {
                    value: "Master",
                    text: {
                      default: "Master",
                      ar: "الماجستير",
                    },
                  },
                  {
                    value: "Doctorat",
                    text: {
                      default: "Doctorat",
                      ar: "الدكتوراه",
                    },
                  },
                ],
                placeholder: "",
                allowClear: false,
                searchEnabled: false,
              },
              {
                type: "text",
                name: "management_data_staff_experience_years",
                title: {
                  default: "Années d’expérience professionnelle",
                  ar: "سنوات الخبرة المهنية",
                },
                isRequired: true,
                errorLocation: "bottom",
                inputType: "number",
                min: 0,
              },
              {
                type: "text",
                name: "management_data_staff_cin_number",
                visibleIf:
                  "{panel.management_data_staff_nationality} = 'Tunisienne'",
                startWithNewLine: false,
                title: {
                  default: "Numéro CIN",
                  ar: "رقم بطاقة التعريف الوطنية",
                },
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                maskType: "pattern",
                maskSettings: {
                  pattern: "99999999",
                },
              },
              {
                type: "text",
                name: "passeport personnel",
                visibleIf:
                  "{panel.management_data_staff_nationality} <> 'Tunisienne'",
                startWithNewLine: false,
                title: {
                  default: "Numéro de passeport",
                  ar: "رقم جواز السفر",
                },
                hideNumber: true,
                isRequired: true,
              },
              {
                type: "file",
                name: "management_data_staff_personal_folder",
                maxWidth: "50%",
                title: {
                  default: "Dossier de personnel",
                  ar: "ملف الموظف",
                },
                isRequired: true,
                filePlaceholder: {
                  default: "Ajouter le dossier du personnel",
                  ar: "إضافة ملف الموظف",
                },
              },
              {
                type: "file",
                name: "management_data_staff_personal_cin_copie",
                visibleIf:
                  "{panel.management_data_staff_nationality} = 'Tunisienne'",
                maxWidth: "50%",
                startWithNewLine: false,
                title: {
                  default: "Copie du CIN",
                  ar: "نسخة رقمية من بطاقة التعريف الوطنية",
                },
                isRequired: true,
                filePlaceholder: {
                  default: "Ajouter la copie du CIN.",
                  ar: "حمّل نسخة رقمية من بطاقة التعريف الوطنية",
                },
              },
              {
                type: "file",
                name: "management_data_staff_personal_passeport_copie",
                visibleIf:
                  "{panel.management_data_staff_nationality} <> 'Tunisienne'",
                maxWidth: "50%",
                startWithNewLine: false,
                title: {
                  default: "Copie du passeport",
                  ar: "نسخة من جواز السفر",
                },
                isRequired: true,
                filePlaceholder: {
                  default: "Ajouter la copie du passeport.",
                  ar: "حمل نسخة من جواز السفر",
                },
              },
            ],
            panelCount: 1,
            minPanelCount: 1,
            panelAddText: {
              default: "Ajouter un personnel",
              ar: "حمل نسخة من جواز السفر",
            },
          },
        ],
      },
    ],
    triggers: [
      {
        type: "runexpression",
        expression: "{legal_entity_reservation_number} <> ''",
        runExpression:
          "getDemandeReservDenomination({legal_entity_reservation_number})",
      },
      {
        type: "runexpression",
        expression: "{establishment_data_tax_number} <> ''",
        runExpression:
          "getExtraitRegistreEntrepriseParId({establishment_data_tax_number})",
      },
      {
        type: "runexpression",
        expression: "{legal_entity_tax_number} <> ''",
        runExpression:
          "getExtraitRegistreEntrepriseParId({legal_entity_tax_number})",
      },
      {
        type: "runexpression",
        expression: "{establishment_data_reservation_number} <> ''",
        runExpression:
          "getDemandeReservDenomination({establishment_data_reservation_number})",
      },
      {
        type: "runexpression",
        expression: "{establishment_director_data_identity} <> ''",
        runExpression:
          "checkDirectorIdentity({establishment_director_data_identity})",
      },
    ],
    sendResultOnPageNext: true,
    showCompletedPage: false,
    showProgressBar: "aboveheader",
    progressBarShowPageTitles: true,
    storeOthersAsComment: false,
    pagePrevText: {
      default: "Retour",
      ar: "السابق",
    },
    pageNextText: {
      default: "Suivant",
      ar: "التالي",
    },
    completeText: "Soumettre",
    previewText: "Aperçu",
    editText: "Modifier",
    showPreviewBeforeComplete: "showAllQuestions",
  
  };


  const themeJson = {
    themeName: "flat",
    colorPalette: "light",
    isPanelless: false,
    backgroundImage: "",
    backgroundOpacity: 1,
    backgroundImageAttachment: "scroll",
    backgroundImageFit: "cover",
    cssVariables: {
      "--sjs-font-size": "13.6px",
      "--sjs-corner-radius": "5px",
      "--sjs-base-unit": "8px",
      "--sjs-shadow-small": "0px 0px 0px 1px rgba(0, 0, 0, 0.15)",
      "--sjs-shadow-inner": "0px 0px 0px 1px rgba(0, 0, 0, 0.12)",
      "--sjs-border-default": "none",
      "--sjs-border-light": "none",
      "--sjs-general-backcolor": "white",
      "--sjs-general-backcolor-dark": "rgba(235, 235, 235, 1)",
      "--sjs-general-backcolor-dim-light": "rgba(255, 255, 255, 1)",
      "--sjs-general-backcolor-dim-dark": "rgba(235, 235, 235, 1)",
      "--sjs-general-forecolor": "rgba(0, 0, 0, 0.91)",
      "--sjs-general-forecolor-light": "rgba(0, 0, 0, 0.45)",
      "--sjs-general-dim-forecolor": "rgba(0, 0, 0, 0.91)",
      "--sjs-general-dim-forecolor-light": "rgba(0, 0, 0, 0.45)",
      "--sjs-secondary-backcolor": "rgba(255, 152, 20, 1)",
      "--sjs-secondary-backcolor-light": "rgba(255, 152, 20, 0.1)",
      "--sjs-secondary-backcolor-semi-light": "rgba(255, 152, 20, 0.25)",
      "--sjs-secondary-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-secondary-forecolor-light": "rgba(255, 255, 255, 0.25)",
      "--sjs-shadow-small-reset": "0px 0px 0px 0px rgba(0, 0, 0, 0.15)",
      "--sjs-shadow-medium":
        "0px 0px 0px 1px rgba(0, 0, 0, 0.1),0px 2px 6px 0px rgba(0, 0, 0, 0.1)",
      "--sjs-shadow-large": "0px 8px 16px 0px rgba(0, 0, 0, 0.1)",
      "--sjs-shadow-inner-reset": "0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
      "--sjs-border-inside": "none",
      "--sjs-special-red-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-special-green": "rgba(25, 179, 148, 1)",
      "--sjs-special-green-light": "rgba(25, 179, 148, 0.1)",
      "--sjs-special-green-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-special-blue": "rgba(67, 127, 217, 1)",
      "--sjs-special-blue-light": "rgba(67, 127, 217, 0.1)",
      "--sjs-special-blue-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-special-yellow": "rgba(255, 152, 20, 1)",
      "--sjs-special-yellow-light": "rgba(255, 152, 20, 0.1)",
      "--sjs-special-yellow-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-article-font-xx-large-textDecoration": "none",
      "--sjs-article-font-xx-large-fontWeight": "700",
      "--sjs-article-font-xx-large-fontStyle": "normal",
      "--sjs-article-font-xx-large-fontStretch": "normal",
      "--sjs-article-font-xx-large-letterSpacing": "0",
      "--sjs-article-font-xx-large-lineHeight": "64px",
      "--sjs-article-font-xx-large-paragraphIndent": "0px",
      "--sjs-article-font-xx-large-textCase": "none",
      "--sjs-article-font-x-large-textDecoration": "none",
      "--sjs-article-font-x-large-fontWeight": "700",
      "--sjs-article-font-x-large-fontStyle": "normal",
      "--sjs-article-font-x-large-fontStretch": "normal",
      "--sjs-article-font-x-large-letterSpacing": "0",
      "--sjs-article-font-x-large-lineHeight": "56px",
      "--sjs-article-font-x-large-paragraphIndent": "0px",
      "--sjs-article-font-x-large-textCase": "none",
      "--sjs-article-font-large-textDecoration": "none",
      "--sjs-article-font-large-fontWeight": "700",
      "--sjs-article-font-large-fontStyle": "normal",
      "--sjs-article-font-large-fontStretch": "normal",
      "--sjs-article-font-large-letterSpacing": "0",
      "--sjs-article-font-large-lineHeight": "40px",
      "--sjs-article-font-large-paragraphIndent": "0px",
      "--sjs-article-font-large-textCase": "none",
      "--sjs-article-font-medium-textDecoration": "none",
      "--sjs-article-font-medium-fontWeight": "700",
      "--sjs-article-font-medium-fontStyle": "normal",
      "--sjs-article-font-medium-fontStretch": "normal",
      "--sjs-article-font-medium-letterSpacing": "0",
      "--sjs-article-font-medium-lineHeight": "32px",
      "--sjs-article-font-medium-paragraphIndent": "0px",
      "--sjs-article-font-medium-textCase": "none",
      "--sjs-article-font-default-textDecoration": "none",
      "--sjs-article-font-default-fontWeight": "400",
      "--sjs-article-font-default-fontStyle": "normal",
      "--sjs-article-font-default-fontStretch": "normal",
      "--sjs-article-font-default-letterSpacing": "0",
      "--sjs-article-font-default-lineHeight": "28px",
      "--sjs-article-font-default-paragraphIndent": "0px",
      "--sjs-article-font-default-textCase": "none",
      "--sjs-general-backcolor-dim": "rgba(0, 0, 255, 0)",
      "--sjs-primary-backcolor": "#21A8A8",
      "--sjs-primary-backcolor-dark": "rgba(30, 153, 153, 1)",
      "--sjs-primary-backcolor-light": "rgba(33, 168, 168, 0.1)",
      "--sjs-primary-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-primary-forecolor-light": "rgba(255, 255, 255, 0.25)",
      "--sjs-special-red": "rgba(229, 10, 62, 1)",
      "--sjs-special-red-light": "rgba(229, 10, 62, 0.1)",
    },
    headerView: "basic",
  };

  const survey = new Model(surveyJson);

  survey.locale = i18n.language;

  survey.applyTheme(themeJson);

  const getDemandeReservDenomination = async (value) => {
    try {
      const { data } = await axios.get(
        "https://apigw.cni.tn/consultation/demandeReservDenomination/numReserv/1.0.0/" +
          value
      );

      if (data.code === "404") {
        return notification.error({
          message: "Erreur",
          description: "Numéro de réservation invalide",
        });
      }
      survey.setValue("legal_entity_official_name", data?.nomination_LATIN);
      survey.setValue(
        "legal_entity_reservation_expiration_date",
        dayjs(data?.date_EXPIRATION).format("YYYY-MM-DD")
      );
      survey.setValue("establishment_data_trade_name", data?.nomination_LATIN);
    } catch (error) {
      console.log(error);
    }
  };

  const getExtraitRegistreEntrepriseParId = async (value) => {
    try {
      const { data } = await axios.get(
        `https://apigw.cni.tn/extraitRegistreEntreprise/id/1.0.0/registreEntrepriseParId/${value}`
      );

      if (data.code === "404") {
        return notification.error({
          message: "Erreur",
          description: "Numéro d’identifiant fiscal / RNE invalide",
        });
      }
      survey.setValue("legal_entity_official_name", data?.nomAssociationLatin);
      survey.setValue(
        "legal_entity_legal_form",
        data?.formeJuridiqueDesc || ""
      );
      // nomCommercialLatin
      // survey.setValue(
      //   "legal_entity_reservation_expiration_date",
      //   dayjs(data?.date_EXPIRATION).format("YYYY-MM-DD")
      // );
      // survey.setValue("establishment_data_trade_name", data?.nomination_LATIN);
    } catch (error) {
      console.log(error);
    }
  };

  const checkDirectorIdentity = (value) => {
    if (value[0] === "self") {
      survey.setValue(
        "establishment_director_data_last_name",
        survey.data.investor_last_name_fr
      );
      survey.setValue(
        "establishment_director_data_first_name",
        survey.data.investor_first_name_fr
      );
      survey.setValue(
        "establishment_director_data_civility",
        survey.data.investor_civility
      );
      survey.setValue(
        "establishment_director_data_email",
        survey.data.investor_email
      );
      survey.setValue(
        "establishment_director_data_nationality",
        survey.data.investor_nationality
      );
      survey.setValue(
        "establishment_director_data_birth_date",
        survey.data.investor_birth_date
      );
      survey.setValue(
        "establishment_director_data_birth_place",
        survey.data.investor_birth_place
      );
      survey.setValue(
        "establishment_director_data_specific_needs",
        survey.data.investor_specific_needs
      );
      survey.setValue(
        "establishment_director_data_cin_number",
        survey.data.investor_cin
      );
      survey.setValue(
        "establishment_director_data_passport",
        survey.data.investor_passport
      );
      survey.setValue(
        "establishment_director_data_address",
        survey.data.investor_residence_address
      );
      survey.setValue(
        "establishment_director_data_governorate",
        survey.data.investor_residence_governorate
      );
      survey.setValue(
        "establishment_director_data_delegation",
        survey.data.investor_residence_delegation
      );
      survey.setValue(
        "establishment_director_data_phone_number",
        survey.data.investor_phone_number
      );
    } else {
      const page = survey.getPageByName(
        "Données de Directeur de l’Établissement"
      );
      if (page) {
        page.questions.forEach((question) => {
          survey.clearValue(question.name); // Clears the value of each question
        });
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      let data;
      if (user?.nationality === "Tunisienne") {
        data = await checkCitizenStatusByCinDn(
          user?.user?.date_naiss_user,
          user?.cin
        );
      }

      // user?.user?.civility === "M." ? "Homme" : "Femme"

      if (sessionStorage.getItem("surveyData")) {
        survey.data = JSON.parse(sessionStorage.getItem("surveyData"));
        survey.currentPageNo = parseInt(sessionStorage.getItem("surveyStep"));
      }

      if (data?.prenomAr) {
        survey.setValue("investor_last_name_ar", data?.prenomAr);
        const prenom_ar = survey.getQuestionByName("investor_last_name_ar");
        prenom_ar.readOnly = true;
      }
      if (data?.nomAr) {
        survey.setValue("investor_first_name_ar", data?.nomAr);
        const name_ar = survey.getQuestionByName("investor_first_name_ar");
        name_ar.readOnly = true;
      }
      if (data?.prenomFr) {
        survey.setValue("investor_last_name_fr", data?.prenomFr || user?.nom);
        const name_fr = survey.getQuestionByName("investor_last_name_fr");
        name_fr.readOnly = true;
      }
      if (data?.nomFr) {
        survey.setValue("investor_first_name_fr", data?.nomFr || user?.prenom);
        const prenom_fr = survey.getQuestionByName("investor_first_name_fr");
        prenom_fr.readOnly = true;
      }

      survey.setValue("investor_email", user?.email);
      survey.setValue("investor_nationality", user?.nationality);
      survey.setValue(
        "investor_civility",
        user?.civility === "M." ? "Homme" : "Femme"
      );
      survey.setValue("investor_cin", user?.cin);
      survey.setValue(
        "investor_birth_date",
        dayjs(user?.user?.date_naiss_user).format("YYYY-MM-DD")
      );
    }

    fetchData();
  }, [survey]);

  // useEffect(() => {
  //   initRequest();
  // }, []);

  FunctionFactory.Instance.register(
    "getDemandeReservDenomination",
    getDemandeReservDenomination
  );

  FunctionFactory.Instance.register(
    "getExtraitRegistreEntrepriseParId",
    getExtraitRegistreEntrepriseParId
  );

  FunctionFactory.Instance.register(
    "checkDirectorIdentity",
    checkDirectorIdentity
  );

  survey.onComplete.add(async (sender, options) => {
    // Prevent form from automatically completing
    options.allowComplete = false;

    setOpenModal(true);
  });

  survey.onValueChanged.add(function (sender, options) {
    sessionStorage.setItem("surveyData", JSON.stringify(survey.data));
    sessionStorage.setItem("surveyStep", survey.currentPageNo);

    if (
      options.name === "establishment_data_training_type" &&
      options.value === "Initiale et continue"
    ) {
      const trainingPanel = survey.getQuestionByName("training_nature_data");
      trainingPanel.panelCount = 2;
    }

    if (options.name.includes("_governorate")) {
      const delegateKey = options.name.replace("_governorate", "_delegation");
      const language = localStorage.getItem("language");
      const selectedGouvernorat = options.value;
      const selectedDelegate = sender.getQuestionByName(delegateKey);

      Object.keys(gouvernorats).map((e) => {
        if (gouvernorats[e][language] === selectedGouvernorat) {
          selectedDelegate.choices =
            gouvernorats[e]["delegations"][language] || [];
        }
      });
    }
  });

  survey.onDynamicPanelItemValueChanged.add(async function (sender, options) {
    const language = localStorage.getItem("language");
    if (options.question.name === "trainer_data") {
      const panelIndex = options.panelIndex;
      const changedData = options.itemValue;

      if (
        changedData.trainer_data_cin_number ||
        changedData.trainer_data_passport
      ) {
        try {
          const data = await getTrainerByNationalityAndIdentity({
            nationality: changedData.trainer_data_nationality,
            cin:
              changedData.trainer_data_nationality === "Tunisienne"
                ? changedData.trainer_data_cin_number
                : null,
            passport:
              changedData.trainer_data_nationality !== "Tunisienne"
                ? changedData.trainer_data_passport
                : null,
          });

          const excludedQuestions = [
            "trainer_data_nationality",
            "trainer_data_passport",
            "trainer_data_cin_number",
          ];

          const panel = options.panel;

          if (!data) {
            notification.error({
              message: t("Error !"),
              description:
                "Aucun formateur trouvé avec les informations fournies, veuillez vérifier les données saisies",
            });

            panel.getQuestions().forEach((question) => {
              if (!excludedQuestions.includes(question.name)) {
                question.visible = false;
              }
            });

            const isVisible = panel.getQuestionByName("isVisible");

            isVisible.value = false;
            return;
          } else {
            panel.getQuestions().forEach((question) => {
              if (!excludedQuestions.includes(question.name)) {
                question.visible = true;
              }
            });

            const isVisible = panel.getQuestionByName("isVisible");

            isVisible.value = true;
          }

          // TODO: IF user is black listed end process and show a notification error message

          survey.data.trainer_data[panelIndex].trainer_data_last_name =
            data?.nom || "";
          survey.data.trainer_data[panelIndex].trainer_data_first_name =
            data?.prenom || "";
          survey.data.trainer_data[panelIndex].trainer_data_email =
            data?.mail || "";
          survey.data.trainer_data[panelIndex].trainer_data_birth_date =
            dayjs(data?.date_naiss_user).format("YYYY-MM-DD") || "";
          survey.data.trainer_data[panelIndex].trainer_data_civility =
            data?.civility === "M." ? "Homme" : "Femme";
          survey.data.trainer_data[panelIndex].trainer_data_phone_number =
            data?.telephone || "";
          survey.data.trainer_data[panelIndex].trainer_data_address =
            data?.adresse || "";
          survey.data.trainer_data[panelIndex].trainer_data_birth_place =
            data?.birthplace || "";
          survey.data.trainer_data[panelIndex].trainer_data_governorate =
            data?.goverment || "";

          const delegationQuestion = panel.getQuestionByName(
            "trainer_data_delegation"
          );
          Object.keys(gouvernorats).map((e) => {
            if (gouvernorats[e][language] === data?.goverment) {
              delegationQuestion.choices =
                gouvernorats[e]["delegations"][language] || [];
            }
          });

          // FEHA ERREUR LEZEM NCHUFUHA BAED
          // delegationQuestion.choices =
          //   gouvernorats[data?.goverment][language] || [];

          survey.data.trainer_data[panelIndex].trainer_data_delegation =
            data?.delegation || "";

          // Update the panel's data
          sender.getQuestionByName("trainer_data").value =
            survey.data.trainer_data;
        } catch (error) {
          notification.error({
            message: t("Error !"),
            description:
              error?.response?.data?.message ||
              "Une erreur est survenue lors de la récupération des données du formateur",
          });
        }
      }
    }

    if (options.question.name === "management_data") {
      if (options.name === "management_data_staff_residence_governorate") {
        const panel = options.panel;

        const delegationQuestion = panel.getQuestionByName(
          "management_data_staff_residence_delegation"
        );

        if (delegationQuestion) {
          Object.keys(gouvernorats).map((e) => {
            if (gouvernorats[e][language] === options.value) {
              delegationQuestion.choices =
                gouvernorats[e]["delegations"][language] || [];
            }
          });
        }
      }
    }
  });

  survey.onCurrentPageChanging.add(function (sender, options) {
    const currentPage = sender.currentPage;

    const currentPageName = currentPage.name;

    if (currentPageName === "page5") {
      options.allowChanging = false;


      const validation = survey.data.trainer_data.forEach((element) => {
        return element.isVisible;
      })

      
      if (validation.includes(false)) {
        options.allowChanging = false;
        notification.error({
          message: t("Error !"),
          description:
            "Veuillez remplir les informations des formateurs avant de continuer",
        })
      } else {
        options.allowChanging = true;
      }
    }

    if (currentPageName === "page4") {
      const establishment_data_training_type = sender.getQuestionByName(
        "establishment_data_training_type"
      ).value;
      if (establishment_data_training_type === "Initiale et continue") {
        const passConditionValues = [];
        const training_nature_data = sender.getQuestionByName(
          "training_nature_data"
        ).value;

        training_nature_data.forEach((element) => {
          if (
            !passConditionValues.includes(element.training_type_data_nature)
          ) {
            passConditionValues.push(element.training_type_data_nature);
          }
        });

        if (
          passConditionValues.includes("Initiale") &&
          passConditionValues.includes("Continue")
        ) {
          options.allowChanging = true;
        } else {
          notification.error({
            message: t("Error !"),
            description:
              "Veuillez selections 2 types de formation (Initiale et Continue)",
          });
          options.allowChanging = false;
        }
      }
    }
  });

  // const initRequest = async (params) => {
  //   try {
  //     const data = await initAffaire(params);

  //     setDefaultValues(data);
  //   } catch (error) {
  //     notification.error({
  //       message: t("Error !"),
  //       description:
  //         error?.response?.data?.message ||
  //         "Une erreur est survenue lors de l'enregistrement de la demande",
  //     });
  //   }
  // };

  // const updateRequest = async (payload) => {
  //   try {
  //     const data = await updateAffaire(payload);

  //     console.log(data);
  //   } catch (error) {
  //     notification.error({
  //       message: t("Error !"),
  //       description:
  //         error?.response?.data?.message ||
  //         "Une erreur est survenue lors de la mise à jour de la demande",
  //     });
  //   }
  // };

  return (
    <>
      <Row className={classes.rowButtonCreate}>
        <Space>
          <Button
            className={classes.buttonCreate}
            // onClick={async () => {
            //   await updateRequest();
            // }}
          >
            <SaveOutlined />
            {t("Sauvgarder au brouillon")}
          </Button>
          <Button
            className={classes.buttonCreate}
            onClick={() => navigate("/etablissements")}
          >
            <UnorderedListOutlined />
            {t("Liste de mes EPF")}
          </Button>
        </Space>
      </Row>

      <Survey model={survey} locale={i18n.language} />
      <Modal
        open={openModal}
        width="80%"
        title="Confirmer"
        icon={<ExclamationCircleOutlined />}
        onOk={async () => {
          


          try {
            if (!confirm) {
              notification.error({
                message: t("Error !"),
                description: t(
                  "Merci de cocher la case pour attester que vous avez pris connaissance des termes du CDC et que vous les acceptez concernant la création d'un EPF."
                ),
              });
              return;
            }

            setLoadingSave(true);

            const payload = {
              ligne_produit: "f90f2aca5c640289d0a29417bcb63a37",
              id_opp: null,
              nom_produit: "THAMM PRODUCT (  )",
              id_produit: "d728587fff2d87201eae0a158507d814",
              cmp: "987654300",
              user_id: user.id_user,
              date_effet: "2024-01-02",
              souscription: "2024-01-02",
              id_fractionnement: 11,
              affected: "1002237",
              data_form_prod: JSON.stringify({
                ...survey.data,
              }),
            };

            await saveAffaire(payload);

            notification.success({
              message: "Succès",
              description: "Votre demande a été enregistrée avec succès",
            });

            sessionStorage.removeItem("surveyData");
            sessionStorage.removeItem("surveyStep");
            setLoadingSave(false);
            navigate("/demandes");
          } catch (error) {
            setLoadingSave(false);
            notification.error({
              message: "Erreur",
              description: "Une erreur est survenue lors de l'enregistrement",
            });
          }
        }}
        onCancel={() => {
          setOpenModal(false);
        }}
        okText={ loadingSave? <Spin /> : t("Soumettre")}
        okButtonProps={{
          disabled: loadingSave,
        }}
        cancelText={t("Annuler")}
        cancelButtonProps={{
          disabled: loadingSave,
        }}
      >
        <PdfViewer setConfirm={setConfirm} />
      </Modal>
    </>
  );
};

export default CreateEpf;
