import axios from "axios";
import { getDateParts } from "../utils";
import { notification } from "antd";
import i18n from "i18next";

export const checkCitizenByCinDn = async (birthDate, cin) => {
  try {
    const dateParts = getDateParts(birthDate);

    let payload = {
      cin,
      jourNaiss: dateParts.day,
      moisNaiss: dateParts.month,
      anneeNaiss: dateParts.year,
    };

    const { data } = await axios.post(
      process.env.REACT_APP_GOVERNORATE_API_CHECK_STAUTS_DN_CIN_URL,
      {
        ...payload,
      },
      {
        headers: {
          apiKey: process.env.REACT_APP_GOVERNORATE_API_CHECK_STAUTS_DN_CIN,
          "Content-Type": "application/json",
        },
      }
    );

    if (data.codeR !== "1") {
      notification.error({
        message: i18n.t("Error !"),
        description: i18n.t("Le Couple CIN/Date de naissance est erroné"),
      });

      return false;
    }

    return true;
  } catch (error) {
    throw error;
  }
};

export const checkCitizenStatusByCinDn = async (birthDate, cin) => {
  try {
    const isAlive = await checkCitizenByCinDn(birthDate, cin);

    if (!isAlive) {
      return isAlive
    }

    const dateParts = getDateParts(birthDate);

    let payload = {
      cin,
      jourNaiss: dateParts.day,
      moisNaiss: dateParts.month,
      anneeNaiss: dateParts.year,
    };

    const { data } = await axios.post(
      process.env.REACT_APP_GOVERNORATE_API_CHECK_CITIZEN_STAUTS_DN_CIN_URL,
      {
        ...payload,
      },
      {
        headers: {
          apikey:
            process.env
              .REACT_APP_GOVERNORATE_API_CHECK_CITIZEN_STATUS_DN_CIN_KEY,
          "Content-Type": "application/json",
        },
      }
    );

    if (data.codeR !== "1") {
      notification.error({
        message: i18n.t("Error !"),
        description: i18n.t("Le Couple CIN/Date de naissance est erroné"),
      });

      return false;
    }

    return data;
  } catch (error) {}
};
