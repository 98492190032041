import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./pages/Home";

import Rtl from "./pages/Formateurs";
import Profile from "./pages/Profile";
import SignUp from "./pages/Signup/SignUp";
import SignIn from "./pages/SignIn/SignIn";
import Main from "./components/layout/Main";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import AppContext from "./context/AppContext";
import Etablissements from "./pages/Etablissements/Etablissements";
import CreateEpf from "./pages/Etablissements/CreateEpf/CreateEpf";
import { useState } from "react";
import Demandes from "./pages/Demandes/Demandes";
import Brouillons from "./pages/Brouillons/Brouillons";
import PdfViewer from "./pages/PdfViewer";

function App() {
  const [role, setRole] = useState(sessionStorage.getItem("role"));
  const [token, setToken] = useState(localStorage.getItem("token"));

  return (
    <AppContext>
      <BrowserRouter>
        <Routes>
          {/* {!token ? (
            <> */}
          <Route path="/signin" element={<SignIn setToken={setToken} setRole={setRole} />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="*" element={<Navigate to="/signin" />} />
          {/* </> */}
          {role !== "formateur" ? (
            <Route element={<Main />}>
              <Route path="/dashboard" element={<Home />} />
              <Route path="/demandes" element={<Demandes />} />
              <Route path="/formateurs" element={<Rtl />} />
              <Route path="/etablissements" element={<Etablissements />} />
              <Route path="/brouillon" element={<Brouillons />} />
              <Route path="/etablissements/save" element={<CreateEpf />} />
              <Route path="/etablissements/save/:id" element={<CreateEpf />} />
              <Route path="/etablissements/view/:id" element={<CreateEpf />} />
              <Route path="/pdf/viewer" element={<PdfViewer />} />
              <Route path="*" element={<Navigate to="/demandes" />} />
            </Route>
          ) : (
            <Route element={<Main />}>
              <Route path="/profile" element={<Profile />} />
              <Route path="*" element={<Navigate to="/profile" />} />
            </Route>
          )}
        </Routes>
      </BrowserRouter>
    </AppContext>
  );
}

export default App;
