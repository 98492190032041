import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Select,
  Card,
  Checkbox,
} from "antd";
import { useTranslation } from "react-i18next";
import GlobalContext from "../context/GlobalContext";
import PhoneInput from "antd-phone-input";
import dayjs from "dayjs";

const Profile = () => {
  const { t } = useTranslation();
  const { Option } = Select;
  const { listNationlité, form, gouvernorats } = useContext(GlobalContext);
  const [nationalité, setNationalité] = useState("Tunisienne");
  const [delegations, setDelegations] = useState([]);
  const handleGouvernoratChange = (value) => {
    setDelegations(gouvernorats[value] || []);
  };

  const initialValues = JSON.parse(sessionStorage.getItem("-x-token-user"))
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        email: initialValues.email,
        lastName: initialValues.nom,
        firstName: initialValues.prenom,
        gender: initialValues.civilite === "M." ? "male" : "female",
        nationality: initialValues.nationality,
        cin: initialValues.cin,
        passportNumber: initialValues.passport,
        // birthPlace: initialValues.birthPlace,
        birthDate: dayjs(initialValues.user.date_naiss_user),
      });
    }
  }, [form, initialValues]);

  return (
    <Form
      layout="vertical"
      style={{ display: "flex", justifyContent: "center" }}
      form={form}
    >
      <Card style={{ width: "90%" }}>
        <Row gutter={16}>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Adresse e-mail")}
              name="email"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  type: "email",
                  message: t("Veuillez vérifier ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Nom")}
              name="lastName"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Prénom")}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Genre")}
              name="gender"
              rules={[
                {
                  required: true,
                  message: t("Veuillez sélectionner un genre."),
                },
              ]}
            >
              <Select>
                <Option value="male">{t("Homme")}</Option>
                <Option value="female">{t("Femme")}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Nationalité")}
              name="nationality"
              rules={[
                {
                  required: true,
                  message: t("Veuillez sélectionner une nationalité."),
                },
              ]}
            >
              <Select
                onChange={(e) => setNationalité(e)}
                options={listNationlité}
              />
            </Form.Item>
          </Col>
          {nationalité === "Tunisienne" && (
            <Col lg={12} md={24}>
              <Form.Item
                label={t("Numéro CIN")}
                name="cin"
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input
                  maxLength={8}
                  onChange={(e) =>
                    form.setFieldValue(
                      "cin",
                      e.target.value.replace(/[^+0-9]/g, "")
                    )
                  }
                />
              </Form.Item>
            </Col>
          )}
          {nationalité !== "Tunisienne" && (
            <Col lg={12} md={24}>
              <Form.Item
                label={t("N° passeport")}
                name="passportNumber"
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input
                  onChange={(e) =>
                    form.setFieldValue(
                      "passportNumber",
                      e.target.value.replace(/[^+0-9]/g, "")
                    )
                  }
                />
              </Form.Item>
            </Col>
          )}
          {nationalité !== "Tunisienne" && (
            <Col lg={12} md={24}>
              <Form.Item
                label={t("Date d’expiration du passeport")}
                name="passportExpiry"
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format="DD/MM/YYYY"
                  placeholder=""
                />
              </Form.Item>
            </Col>
          )}
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Date de naissance")}
              name="birthDate"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                style={{ width: "100%" }}
                placeholder=""
              />
            </Form.Item>
          </Col>
          {/* <Col lg={12} md={24}>
            <Form.Item
              label={t("Lieu de naissance")}
              name="birthPlace"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col> */}

          {/* <Col lg={12} md={24}>
            <Form.Item
              label={t("Adresse")}
              name="address"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Gouvernorat de résidence")}
              name="residenceGovernorate"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Select onChange={handleGouvernoratChange}>
                {Object.keys(gouvernorats).map((gouvernorat) => (
                  <Option key={gouvernorat} value={gouvernorat}>
                    {gouvernorat}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Délégation de résidence")}
              name="residenceDelegation"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Select>
                {delegations.map((delegation) => (
                  <Option key={delegation} value={delegation}>
                    {delegation}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Code postal")}
              name="postalCode"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={12} md={24}>
            <Form.Item
              label={t("Numéro de téléphone")}
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <PhoneInput
                country="tn" // Default country code
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Mot de passe")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Confirmer le mot de passe")}
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("Les mots de passe ne correspondent pas."))
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Personne à besoin spécifique")}
              name="specialNeeds"
              valuePropName="checked"
            >
              <Checkbox>{t("Aucun Personne à besoin spécifique")}</Checkbox>
            </Form.Item>
          </Col> */}
        </Row>
      </Card>
    </Form>
  );
};

export default Profile;
